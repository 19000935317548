import React from 'react';

import Template from 'templates/MainTemplate';


const View = () => {
	return (
		<Template>
			<section className="ts-section --m">
				<div className="container">
					<div className="text-center space-y-8">
						<div className="ts-heading --h1 text-primary">404</div>

						<div className="ts-content">
							<p>Nie znaleziono strony o&nbsp;podanym adresie.</p>
						</div>
					</div>
				</div>
			</section>
		</Template>
	);
};


export default View;