import React from 'react';
import PropTypes from 'prop-types';

import container from 'redux/container';

import FieldPlusMinus from 'templates/MainTemplate/fields/FieldPlusMinus';
import FieldSelect from 'templates/MainTemplate/fields/FieldSelect';
import FieldColorsList from 'templates/MainTemplate/fields/FieldColorsList';
import Manufactures from '../Manufactures';


class Settings extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.props.object;
	}

	set = (name, value) => {
		container.app.actions.setSetting(name, value);
	}

	render() {
		return (
			<>
				<div className="ts-view__items --sh">
					<div className="space-y-6 2xl:space-y-12">
						<div className="space-y-3">
							<div className="ts-heading --h5 pb-2">Wysokość</div>

							{(false) ? (
								<FieldPlusMinus
									actionPlus={() => container.app.actions.setHeight(1)}
									actionMinus={() => container.app.actions.setHeight(-1)}
									description={`Wysokość ogrodzenia (${container.app.getSystem().settings.minHeight * 100} - ${container.app.getSystem().settings.maxHeight * 100} cm)`}
									value={`${container.app.settings.height * 100} cm`}
								/>
							) : (
								<div className="ts-content text-sm">
									<p>{`Wysokość ogrodzenia: ${container.app.settings.height * 100} cm`}</p>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="ts-view__items --b bg-white --grow">
					<div className="space-y-10">
						<div className="space-y-3">
							<div className="ts-heading --h5">Profile</div>

							<FieldSelect
								action={(value, p) => container.app.actions.setSystemSetting('profile', 'material', value, p)}
								title="Wybierz rodzaj:"
								options={container.app.getSystem().profile.materials}
								value={container.app.settings.profile.material}
								color="gray"
							/>

							<FieldColorsList
								action={(value, p) => container.app.actions.setSystemSetting('profile', 'color', value, p)}
								title="Wybierz kolor:"
								options={container.app.getSystem().profile.colors}
								value={container.app.settings.profile.color}
							/>

							{(container.app.getSystem().profile.colors[container.app.settings.profile.color].mixes) && (
								<FieldSelect
									action={(value, p) => container.app.actions.setSystemSetting('profile', 'mix', value, p)}
									title="Wybierz mix:"
									options={container.app.getSystem().profile.mixes}
									value={container.app.settings.profile.mix}
									color="gray"
								/>
							)}
						</div>

						<div className="space-y-3">
							<div className="ts-heading --h5">Słupki</div>

							<FieldSelect
								action={(value, p) => container.app.actions.setSystemSetting('pole', 'material', value, p)}
								title="Wybierz rodzaj:"
								options={container.app.getSystem().pole.materials}
								value={container.app.settings.pole.material}
								color="gray"
							/>

							{(container.app.getPoleMaterial().type !== 'hollow') && (
								<FieldColorsList
									action={(value, p) => container.app.actions.setSystemSetting('pole', 'color', value, p)}
									title="Wybierz kolor:"
									options={container.app.getSystem().pole.colors}
									value={container.app.settings.pole.color}
								/>
							)}
						</div>

						<div className="space-y-3">
							<div className="ts-heading --h5">Przęsła</div>

							<FieldSelect
								action={(value, p) => container.app.actions.setSystemSetting('wall', 'material', value, p)}
								title="Wybierz rodzaj:"
								options={container.app.getSystem().wall.materials}
								value={container.app.settings.wall.material}
								color="gray"
							/>
						</div>

						<Manufactures />
					</div>
				</div>
			</>
		);
	}
}


Settings.defaultProps = {
	object: null,
};

Settings.propTypes = {
	object: PropTypes.object,
};


export default Settings;