import store from 'redux/store';
import types from './types';


const set = (type, state = null, object = null) => {
	store.dispatch({ type: types.SET, content: { type, state, object } });
};

const reset = () => {
	store.dispatch({ type: types.RESET });
};


const getView = () => store.getState().panel.view;

const setView = (view) => {
	store.dispatch({ type: types.SET_VIEW, view });
};


const getMode = () => store.getState().panel.mode;

const setMode = (mode) => {
	store.dispatch({ type: types.SET_MODE, mode });
};


export {
	set,
	reset,

	getView,
	setView,

	getMode,
	setMode,
};