import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import container from 'redux/container';
import * as actions from 'modules/App/redux/actions';
import * as panelActions from '../../redux/panel/actions';

import Menu from './Menu';
import Panel from './Panel';
import Workspace from './Workspace';

import Popup from 'templates/MainTemplate/Popup';
import Modal from 'templates/MainTemplate/Modal';
import ButtonBar from 'templates/MainTemplate/buttons/ButtonBar';
import ButtonFullScreen from 'templates/MainTemplate/buttons/ButtonFullScreen';
import ButtonHelp from 'templates/MainTemplate/buttons/ButtonHelp';
import ButtonSwitchGround from 'templates/MainTemplate/buttons/ButtonSwitchGround';
import Loading from 'views/Loading';

import App from '../../classes/App';


class Application extends React.Component {
	componentDidMount = () => {
		container.app = new App({
			canvas: [this.workspace.canvas],
			config: this.props.config,
			debug: this.props.debug,
			id: this.props.id,
			hash: this.props.hash,
			mode: this.props.mode,
			path: this.props.path,
			email: this.props.email,
			project: this.props.project,
		});
		container.workspace = this.workspace;
	}

	render() {
		return (
			<>
				<header
					id="ts-header"
					className={classNames(
						'ts-header --subpage',
						!actions.get('bar') ? '--full' : null,
					)}
				>
					<div className="ts-header__wp bg-white">
						<div className="lg lg:flex-none">
							<ButtonBar />
						</div>

						<div className="lg:flex-grow">
							<Menu />
						</div>

						<div className="lg:flex-none">
							<div className="flex items-center h-full">
								<div>
									<ButtonHelp />
								</div>

								{(!actions.get('popup') && panelActions.getView() === '3d') && (
									<div>
										<ButtonSwitchGround />
									</div>
								)}

								<div>
									<ButtonFullScreen />
								</div>
							</div>
						</div>

						{(actions.get('mode') !== 'question') && (
							<div className="lg:flex-none">
								<button
									onClick={() => container.app.extensions.valuation.execute()}
									type="button"
									className={classNames(
										'ts-btn --primary text-md h-full ts-header__btn',
										actions.get('popup') ? 'active' : null,
									)}
								>
									<span>Podsumowanie</span>
								</button>
							</div>
						)}
					</div>
				</header>

				<div
					className={classNames(
						'ts-main__bg-wp',
						!actions.get('bar') ? '--full' : null,
					)}
				>
					<div className="ts-main__embed bg-black --in">
						<Workspace
							ref={(element) => {
								this.workspace = element;
							}}
						/>

						{(actions.get('loading')) && (
							<div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, background: '#f2f2f2' }}>
								<Loading text={actions.get('loading')} />
							</div>
						)}
					</div>

					<div className="tsWidget">
						<div className="tsWidget__Rotate tsWidget__Item">
							<button onClick={() => { container.app.three.camera.controls.rotateLeft(); }} type="button" aria-label="Obróć w lewo">
								<span className="icon-rotate-left" />
							</button>
							<button onClick={() => { container.app.three.camera.controls.rotateRight(); }} type="button" aria-label="Obróć w prawo">
								<span className="icon-rotate-right" />
							</button>
						</div>

						<div className="tsWidget__Zoom tsWidget__Item">
							<button onClick={() => { container.app.three.camera.controls.zoomIn(); }} type="button" aria-label="Powiększ">
								<span className="icon-plus2" />
							</button>
							<button onClick={() => { container.app.three.camera.controls.zoomOut(); }} type="button" aria-label="Pomniejsz">
								<span className="icon-minus2" />
							</button>
						</div>
					</div>
				</div>

				<Panel />

				{(actions.get('popup')) && (
					<Popup back="wróć do projektu" content={actions.get('popup')} />
				)}

				{(actions.get('modal')) && (
					<Modal title={actions.get('modal').title} content={actions.get('modal').content} />
				)}
			</>
		);
	}
}


Application.defaultProps = {
	config: null,
	debug: false,
	id: '',
	hash: '',
	mode: '',
	path: '',
	email: '',
	project: null,
};

Application.propTypes = {
	config: PropTypes.object,
	debug: PropTypes.bool,
	id: PropTypes.string,
	hash: PropTypes.string,
	mode: PropTypes.string,
	path: PropTypes.string,
	email: PropTypes.string,
	project: PropTypes.object,
};


export default Application;