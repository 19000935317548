import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as actions from 'modules/App/redux/actions';

import Menu from './Menu';


const Template = (props) => {
	const [menu, setMenu] = useState(false);

	return (
		<>
			<Menu visible={menu} />

			<div className="hidden lg:block">
				<aside
					className={classNames(
						'ts-aside',
						!actions.get('bar') ? 'slide-inleft' : 'slide-inright',
						actions.get('barClicked') ? '--clicked' : null,
					)}
				>
					<a href="/" className="ts-logo">
						<img src="/themes/img/logo.svg" alt="logo" />
					</a>

					<button onClick={() => setMenu(!menu)} type="button" className="ts-aside__btn" aria-label="Menu">
						<span className="icon-menu" />
					</button>
				</aside>

				<main
					className={classNames(
						'ts-main',
						props.classMain,
					)}
				>
					{props.children}
				</main>
			</div>

			<div className="ts-main lg:hidden">
				<div className="ts-main__embed">
					<picture className="ts-picture ts-main__bg">
						<source type="image/webp" media="(max-width: 414px)" srcSet="/themes/img/tmp/bkg_m.webp" />
						<source type="image/jpeg" media="(max-width: 414px)" srcSet="/themes/img/tmp/bkg_m.jpg" />

						<source type="image/webp" media="(min-width: 415px)" srcSet="/themes/img/tmp/bkg_t.webp" />
						<source type="image/jpeg" media="(min-width: 415px)" srcSet="/themes/img/tmp/bkg_t.jpg" />

						<img className="lazy" srcSet="/themes/img/tmp/bkg_m.jpg 414w, /themes/img/tmp/bkg_t.jpg 415w" src="/themes/img/tmp/bkg_m.jpg" alt="" />
					</picture>
				</div>

				<header className="ts-header">
					<div className="container">
						<div className="ts-header__wp items-center justify-between">
							<div>
								<a href="/" className="ts-logo">
									<img src="/themes/img/logo.svg" alt="logo" />
								</a>
							</div>

							<div>
								<button onClick={() => setMenu(!menu)} type="button" aria-label="Menu" className="ts-header__btn-menu">
									<span className="icon-menu" />
								</button>
							</div>
						</div>
					</div>
				</header>

				<div className="ts-main__page">
					<div className="container">
						<div className="ts-panel">
							<div className="space-y-10">
								<div className="ts-heading --h3">Przepraszamy!</div>
								<div className="ts-content">
									<p>Kreator nie jest dostępny w wersji mobilnej, aby w nim wygodnie pracować skorzystaj z komputera.</p>
								</div>

								<div className="pt-2">
									<a href="https://www.gardsystems.eu/" rel="noreferrer" className="ts-btn --dark --wide">
										<span>Przejdź do gardsystems.eu</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};


Template.defaultProps = {
	classMain: null,
};

Template.propTypes = {
	children: PropTypes.node.isRequired,
	classMain: PropTypes.string,
};

export default Template;