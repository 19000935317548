import Geometry from 'classes/Three/Geometry';
import Helper from 'classes/Tools/Helper';


class WicketGeometry extends Geometry {
	constructor(width, height, data, direction) {
		super({
			unwrap: 'wrap',
		});

		let w1;
		let w2;

		let d = 0;

		let wx1;
		let wx2;
		let hx1;
		let hx2;
		let dx1;
		let dx2;

		switch (direction) {
			case 'left':
				w1 = -width / 2 + 0.005;
				w2 = width / 2 - data.hinge.width;
				break;

			case 'right':
				w1 = -width / 2 + data.hinge.width;
				w2 = width / 2 - 0.005;
				break;

			default:
		}


		/* --- FRAME ---------------------------------------------- */

		// vertical
		hx1 = data.frame.height;
		hx2 = height - data.frame.height;

		dx1 = d - data.frame.depth / 2;
		dx2 = d + data.frame.depth / 2;

		for (let x = 1; x <= 2; x++) {
			switch (x) {
				// left
				case 1:
					wx1 = w1;
					wx2 = wx1 + data.frame.width;
					break;

				// right
				case 2:
					wx1 = w2 - data.frame.width;
					wx2 = w2;
					break;

				default:
			}

			this.rect(wx1, wx2, hx1, hx2, dx1, dx2, 3);
		}

		// horizontal
		wx1 = w1;
		wx2 = w2;

		dx1 = d - data.frame.depth / 2;
		dx2 = d + data.frame.depth / 2;

		for (let x = 1; x <= 2; x++) {
			switch (x) {
				// top
				case 1:
					hx1 = height - data.frame.height;
					hx2 = height;
					break;

				// bottom
				case 2:
					hx1 = 0;
					hx2 = hx1 + data.frame.height;
					break;

				default:
			}

			this.rect(wx1, wx2, hx1, hx2, dx1, dx2, 3);
		}


		/* --- SLATS ---------------------------------------------- */

		hx1 = data.frame.height;
		hx2 = height - data.frame.height;

		dx1 = d - data.slat.depth / 2;
		dx2 = d + data.slat.depth / 2;

		for (let x = 1; x <= 2; x++) {
			switch (x) {
				// left
				case 1:
					wx1 = w1 + data.frame.width;
					break;

				// right
				case 2:
					wx1 = w2 - data.frame.width - data.slat.width;
					break;

				default:
			}

			this.rect(wx1, wx1 + data.slat.width, hx1, hx2, dx1, dx2, 2);
		}


		/* --- PANELS --------------------------------------------- */

		wx1 = w1 + data.frame.width;
		wx2 = w2 - data.frame.width;

		dx1 = d - data.panel.depth / 2;
		dx2 = d + data.panel.depth / 2;

		for (let x = 1; x <= data.counts.panels; x++) {
			hx1 = data.frame.height + data.space + ((x - 1) * (data.panel.height + data.panel.space.wicket)) + this.getFraud(-x, data);
			hx2 = hx1 + data.panel.height + this.getFraud(x, data);

			this.rect(wx1, wx2, hx1, hx2, dx1, dx2, this.mix({ all: data.counts.panels, current: x }, data.mix, [0, 1]));
		}

		this.geometry.computeFaceNormals();

		return this.geometry;
	}

	getFraud = (x, data) => {
		if (x < -1) {
			return Helper.aN(data.fraud?.wicket?.top);
		}

		if (x === 1) {
			return Helper.aN(data.fraud?.wicket?.top);
		}

		if (x === data.counts.panels) {
			return Helper.aN(data.fraud?.wicket?.bottom);
		}

		return 0;
	}
}


export default WicketGeometry;