/* eslint-disable quote-props */

class Config {
	constructor() {
		this.systemsDir = '/assets/img/systems/';
		this.systems = [];

		this.loadSystems();
	}

	loadSystems = () => {
		this.systems.modern = this.Modern();
	}

	Modern = () => ({
		label: 'Modern',
		default: {
			height: 1.50,
		},
		settings: {
			minHeight: 1.20,
			maxHeight: 1.80,
			stepHeight: 0.10,
			space: {
				bottom: {
					pole: 0.08,
					wall: 0.25,
					gateSliding: 0.08,
					gateSwing: 0.08,
					wicket: 0.08,
				},
			},
		},
		profile: {
			default: {
				material: '220_24',
				color: 'czarny-zloty_dab',
				mix: 'one',
			},
			materials: {
				'220_24': {
					label: 'Deska 220x24',
					panel: {
						height: 0.220,
						depth: 0.024,
						space: {
							wall: 0.031,
							gateSliding: 0.033,
							gateSwing: 0.026,
							wicket: 0.026,
						},
					},
					fraud: {
						gateSwing: {
							top: -0.060,
							bottom: -0.060,
						},
						wicket: {
							top: -0.060,
							bottom: -0.060,
						},
					},
				},
				'160_24': {
					label: 'Deska 160x24',
					panel: {
						height: 0.160,
						depth: 0.024,
						space: {
							wall: 0.053,
							gateSliding: 0.049,
							gateSwing: 0.033,
							wicket: 0.033,
						},
					},
				},
				'100_24': {
					label: 'Deska 100x24',
					panel: {
						height: 0.100,
						depth: 0.024,
						space: {
							wall: 0.041,
							gateSliding: 0.040,
							gateSwing: 0.035,
							wicket: 0.035,
						},
					},
				},
			},
			colors: {
				'grafit-grafit': {
					label: 'Grafit 7016',
					type: 'color',
					source: '#464f53',
					variants: {
						primary: { type: 'color', source: '#464f53', metalness: 0.15, roughness: 0.75 },
						secondary: { type: 'color', source: '#464f53', metalness: 0.15, roughness: 0.75 },
					},
					mixes: false,
				},
				'czarny-czarny': {
					label: 'Czarny 9005',
					type: 'color',
					source: '#292929',
					variants: {
						primary: { type: 'color', source: '#292929', metalness: 0.15, roughness: 0.75 },
						secondary: { type: 'color', source: '#292929', metalness: 0.15, roughness: 0.75 },
					},
					mixes: false,
				},
				'czarny-orzech': {
					label: 'Orzech',
					type: 'texture',
					source: 'orzech-min.jpg',
					variants: {
						primary: { type: 'color', source: '#292929', metalness: 0.15, roughness: 0.75 },
						secondary: { type: 'texture', source: 'orzech.jpg', metalness: 0.15, roughness: 0.75 },
					},
					mixes: true,
				},
				'czarny-zloty_dab': {
					label: 'Złoty dąb',
					type: 'texture',
					source: 'zloty_dab-min.jpg',
					variants: {
						primary: { type: 'color', source: '#292929', metalness: 0.15, roughness: 0.75 },
						secondary: { type: 'texture', source: 'zloty_dab.jpg', metalness: 0.15, roughness: 0.75 },
					},
					mixes: true,
				},
			},
			mixes: {
				'all': {
					label: 'Wszystkie deski',
					type: 'all',
					variant: [],
				},
				'one': {
					label: 'Druga deska',
					type: 'top',
					variant: { offset: 1, amount: 1 },
				},
			},
		},
		pole: {
			default: {
				material: '80-concrete',
				color: 'czarny',
			},
			materials: {
				'80-concrete': {
					label: '80x80 do zabetonowania',
					type: 'default',
					width: 0.08,
					depth: 0.08,
					roof: {
						width: 0.085,
						height: 0.02,
						depth: 0.085,
					},
				},
				'80-screw': {
					label: '80x80 do przykręcenia',
					type: 'default',
					width: 0.08,
					depth: 0.08,
					roof: {
						width: 0.085,
						height: 0.02,
						depth: 0.085,
					},
					base: {
						width: 0.18,
						height: 0.003,
						depth: 0.18,
					},
				},
				'100-concrete': {
					label: '100x100 do zabetonowania',
					type: 'default',
					width: 0.10,
					depth: 0.10,
					roof: {
						width: 0.105,
						height: 0.02,
						depth: 0.105,
					},
				},
				'100-screw': {
					label: '100x100 do przykręcenia',
					type: 'default',
					width: 0.10,
					depth: 0.10,
					roof: {
						width: 0.105,
						height: 0.02,
						depth: 0.105,
					},
					base: {
						width: 0.20,
						height: 0.003,
						depth: 0.20,
					},
				},
				'500_200-hollow': {
					label: '500x200 betonowy',
					type: 'hollow',
					width: 0.50,
					depth: 0.20,
				},
			},
			colors: {
				'grafit': {
					label: 'Grafit 7016',
					type: 'color',
					source: '#464f53',
					variants: {
						default: { type: 'color', source: '#464f53', metalness: 0.15, roughness: 0.75 },
					},
				},
				'czarny': {
					label: 'Czarny 9005',
					type: 'color',
					source: '#292929',
					variants: {
						default: { type: 'color', source: '#292929', metalness: 0.15, roughness: 0.75 },
					},
				},
			},
		},
		wall: {
			default: {
				material: 'standard',
			},
			settings: {
				minWidth: 0.50,
				maxWidth: 2.50,
			},
			materials: {
				'standard': {
					label: 'Standard',
					slat: {
						width: 0.03,
						depth: 0.03,
						roof: {
							height: 0.024,
						},
					},
					holder: {
						width: 0.02, // 1)
						height: 0.02, // 2)
						depth: 0.02, // 3)
						space: 0.08, // 4)
					},
				},
				'premium': {
					label: 'Premium',
					slat: {
						width: 0.03,
						depth: 0.03,
						roof: {
							height: 0.024,
						},
					},
				},
			},
		},
		gateSliding: {
			accessory: {
				drive: {
					'': 'Brak',
					'drive-somfy_elixo_500': 'Automat Somfy Elixo 500 RTS',
					'drive-somfy_elixo_800': 'Automat Somfy Elixo 800 RTS',
				},
			},
			settings: {
				width: {
					step: 0.10,
					min: 3.00,
					max: 6.00,
					default: 4.00,
				},
			},
			frame: {
				width: 0.06,
				height: 0.06,
				depth: 0.06, // 7)
				center: {
					width: 0.06,
					depth: 0.06,
				},
				bottom: {
					height: 0.08,
					depth: 0.08,
				},
				extra: 2.00,
			},
			slat: {
				width: 0.03,
				depth: 0.03,
			},
		},
		gateSwing: {
			accessory: {
				drive: {
					'': 'Brak',
					'drive-somfy_ixengo_3s': 'Automat Somfy Ixengo 3S RTS',
				},
			},
			settings: {
				width: {
					step: 0.10,
					min: 3.00,
					max: 6.00,
					default: 4.00,
				},
			},
			between: 0.01,
			hinge: {
				width: 0.039,
				height: 0.036,
				depth: 0.025,
				space: 0.15,
			},
			frame: {
				width: 0.06, // 6)
				height: 0.06,
				depth: 0.06, // 7)
			},
			slat: {
				width: 0.03,
				depth: 0.03,
			},
		},
		wicket: {
			accessory: {
				lock: {
					'': 'Brak',
					'lock-knob_handle': 'Elektrozamek do furtki gałka/klamka',
				},
			},
			settings: {
				width: {
					step: 0.10,
					min: 0.80,
					max: 1.60,
					default: 1.00,
				},
			},
			handle: {
				space: 0.92,
			},
			hinge: {
				width: 0.039,
				height: 0.036,
				depth: 0.025,
				space: 0.15,
			},
			frame: {
				width: 0.06, // 6)
				height: 0.06,
				depth: 0.06, // 7)
			},
			slat: {
				width: 0.03,
				depth: 0.03,
			},
		},
	});
}


export default Config;