import React from 'react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';

import config from 'config';
import container from 'redux/container';
import store from 'redux/store';

import Modules from 'Modules';
import Routes from 'Routes';


class Root extends React.Component {
	constructor() {
		super();

		this.init();

		this.state = {
			view: 'loading',
		};
	}

	componentDidMount = () => {
		this.setState({ view: 'default' });
	}

	init = () => {
		let modules = new Modules();

		container.config = config;
		container.routes = modules.generateRoutes();
		container.menus = {
			main: modules.generateMenu('main'),
		};

		store.replaceReducer(combineReducers(modules.reducers));
	}

	render() {
		switch (this.state.view) {
			default:
				return (
					<Provider store={store}>
						<Routes />
					</Provider>
				);

			case 'loading':
				return (
					<></>
				);
		}
	}
}


export default Root;