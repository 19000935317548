import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as actions from 'modules/App/redux/actions';


const Popup = (props) => {
	return (
		<div
			className={classNames(
				'ts-biglayer',
				!actions.get('bar') ? '--full' : null,
			)}
		>
			<section className="ts-section lg:h-spage">
				<div className="section-scrolled">
					<div className="container xl:px-16 2xl:px-0">
						<div className="ts-fpan">
							<div className="ts-fpan__header">
								<button onClick={() => actions.popup(null)} className="ts-btni group text-org">
									<span className="icon-prev" />
									<span className="ts-btni__t">{props.back}</span>
								</button>
							</div>

							{props.content}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};


Popup.defaultProps = {
	back: 'wróć',
};

Popup.propTypes = {
	back: PropTypes.string,
	content: PropTypes.node.isRequired,
};


export default Popup;