import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';

import * as actions from 'modules/App/redux/actions';

import Http from 'classes/Http';
import GTM from 'classes/GTM';

import Template from 'templates/MainTemplate';
import ButtonBar from 'templates/MainTemplate/buttons/ButtonBar';
import ButtonFullScreen from 'templates/MainTemplate/buttons/ButtonFullScreen';
import ButtonHelp from 'templates/MainTemplate/buttons/ButtonHelp';
import Loading from 'views/Loading';
import SearchForm from '../components/SearchForm';
import ILiteMap from 'components/maps/ILiteMap';


class View extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			view: 'default',
			address: '',
			x: null,
			y: null,
		};
	}

	searchAndLoad = (data) => {
		GTM.send(null, 'Location');
		this.setState({ view: 'map', address: data.address, x: data.x, y: data.y });
	}

	searchAndMove = (data) => {
		this.setState({ address: data.address, x: data.x, y: data.y }, () => {
			if (this.map) {
				this.map.move({ x: this.state.x, y: this.state.y });
			}
		});
	}

	run = (x, y) => {
		if (x && y) {
			this.setState({ view: 'loading' });

			Http.post('projects/add', {
				data: {
					address: this.state.address,
					x,
					y,
				},
				success: (response) => {
					if (response.status && response.id && response.hash) {
						GTM.send(null, 'Draw');
						this.setState({ view: 'redirect', url: `/project/${response.id}/${response.hash}` });
					}
				},
			});
		}
	}

	render() {
		switch (this.state.view) {
			default:
				return (
					<Template>
						<div className="ts-main__embed bg-black --start lg:top-0">
							<picture className="ts-main__bg">
								<source type="image/webp" srcSet="/themes/img/tmp/bg.webp" />
								<source type="image/jpeg" srcSet="/themes/img/tmp/bg.jpg" />

								<img className="lazy" srcSet="/themes/img/tmp/bg.jpg" src="/themes/img/tmp/bg.jpg" alt="" />
							</picture>
						</div>

						<section className="ts-section min-h-spage">
							<div className="container">
								<div className="ts-panel animate-fadein">
									<div className="space-y-2.5 xl:space-y-5">
										<div className="ts-heading --h3">Zaprojektuj swoje ogrodzenie</div>
										<div className="ts-content">
											<p>Aby rozpocząć, wpisz lokalizację domu lub działki.</p>
										</div>
									</div>

									<div>
										<SearchForm type="vertical" address={this.state.address} button="Rozpocznij!" submit={this.searchAndLoad} />
									</div>

									{(false) && (
										<div>
											<button type="button" className="ts-panel__u group">
												<span className="icon-upload" />
												<span className="ts-panel__u-text">Wczytaj zapisany projekt</span>
											</button>
										</div>
									)}
								</div>
							</div>
						</section>
					</Template>
				);

			case 'map':
				return (
					<Template classMain="--column">
						<header
							id="ts-header"
							className={classNames(
								'ts-header --subpage',
								!actions.get('bar') ? '--full' : null,
							)}
						>
							<div className="ts-header__wp">
								<div className="lg lg:flex-none">
									<ButtonBar />
								</div>

								<div className="pr-10 lg:flex-grow">
									<SearchForm type="horizontal" address={this.state.address} button="szukaj" submit={this.searchAndMove} />
								</div>

								<div className="lg:flex-none">
									<div className="bg-white flex items-center h-full">
										<div>
											<ButtonHelp />
										</div>

										<div>
											<ButtonFullScreen />
										</div>
									</div>
								</div>
							</div>
						</header>

						<ILiteMap
							marker={{ x: this.state.x, y: this.state.y }}
							crop={this.run}
							ref={(element) => {
								this.map = element;
							}}
						/>
					</Template>
				);

			case 'redirect':
				return (
					<Navigate to={this.state.url} />
				);

			case 'loading':
				return (
					<Template>
						<Loading text="Trwa generowanie mapy..." />
					</Template>
				);
		}
	}
}


const mapStateToProps = (state) => (state.app);

export default connect(mapStateToProps)(View);