import * as THREE from 'three';
import { GLTFLoader } from 'three-gltfloader';
import Stats from 'stats-js';

import Objects from 'classes/Tools/Objects';


class Three {
	constructor(instance, canvas) {
		this.instance = instance;
		this.canvas = canvas;

		this.check3dSupport();
		this.stats();
		this.init();
	}

	check3dSupport = () => {
		let canvas = document.createElement('canvas');
		let webgl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

		if (webgl && webgl instanceof WebGLRenderingContext) {
			// alert('Congratulations! Your browser supports WebGL.');
		} else {
			// alert('Your browser or device may not support WebGL.');
		}
	}

	stats = () => {
		if (this.instance.debug) {
			this.stats = new Stats();
			this.stats.domElement.style.position = 'absolute';
			this.stats.domElement.style.top = 'auto';
			this.stats.domElement.style.left = '0';
			this.stats.domElement.style.right = 'auto';
			this.stats.domElement.style.bottom = '0';
			this.stats.domElement.style.zIndex = 1;

			this.canvas.appendChild(this.stats.domElement);
		} else {
			this.stats = null;
		}
	}

	init = () => {
		this.setSizeAndAspect();

		// dependencies
		this.render = new THREE.WebGLRenderer({
			alpha: true,
			antialias: this.instance.details.antialiassing === 'on',
		});

		this.loader = new THREE.TextureLoader();
		this.gltf = new GLTFLoader();

		// settings
		this.render.setPixelRatio((window.devicePixelRatio < 2) ? window.devicePixelRatio : 2);
		this.render.setSize(this.width, this.height);
		this.render.shadowMap.enabled = true;
		this.render.sortObjects = false;

		// add to canvas
		this.canvas.insertBefore(this.render.domElement, this.canvas.firstChild);

		// window events
		this.resize();

		// run animation
		this.reqestAnimation();
	}

	reqestAnimation = () => {
		window.requestAnimationFrame(() => {
			if (this.instance.details.framelimit === 'on') {
				setTimeout(() => {
					this.refresh();
					this.reqestAnimation();
				}, 15);
			} else {
				this.refresh();
				this.reqestAnimation();
			}
		});
	}

	refresh = () => {
		if (this.animations) {
			this.animations.render();
		}

		if (this.scene && this.camera) {
			this.render.render(this.scene, this.camera);
		}

		if (this.labels) {
			this.labels.render();
		}

		if (this.stats) {
			this.stats.update();
		}
	}

	reset = () => {
		this.setSizeAndAspect();

		Objects.values(this.cameras).forEach((camera) => {
			camera.aspect = this.aspect;
			camera.updateProjectionMatrix();
		});

		this.render.setSize(this.width, this.height);
	}

	resize = () => {
		['resize'].forEach((event) => window.addEventListener(event, () => {
			this.reset();
		}));
	}

	setSizeAndAspect = () => {
		this.width = this.canvas.offsetWidth;
		this.height = this.canvas.offsetHeight;
		this.aspect = this.width / this.height;
	}
}


export default Three;