import React from 'react';
import PropTypes from 'prop-types';


const FieldPlusMinus = (props) => {
	return (
		<>
			{props.title && (
				<div className="ts-content text-sm">
					<p>{props.title}</p>
				</div>
			)}

			<label className="ts-counter">
				<input type="text" value={props.value} className="ts-input --sm --sh" placeholder={props.value} aria-label={props.value} readOnly />

				<div className="ts-counter__btn">
					<button onClick={props.actionPlus} type="button" className="icon-plus" aria-label="Powiększ" />
					<button onClick={props.actionMinus} type="button" className="icon-minus" aria-label="Pomniejsz" />
				</div>
			</label>

			{props.description && (
				<div className="ts-content text-sm">
					<p>{props.description}</p>
				</div>
			)}
		</>
	);
};


FieldPlusMinus.defaultProps = {
	actionPlus: null,
	actionMinus: null,
	title: null,
	description: null,
	value: null,
};

FieldPlusMinus.propTypes = {
	actionPlus: PropTypes.func,
	actionMinus: PropTypes.func,
	title: PropTypes.string,
	description: PropTypes.string,
	value: PropTypes.string,
};


export default FieldPlusMinus;