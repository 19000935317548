import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import container from 'redux/container';

import Http from 'classes/Http';
import GTM from 'classes/GTM';

import Loading from 'views/Loading';


const ContactForm = (props) => {
	const [view, setView] = useState('default');
	const [content, setContent] = useState();

	const [loading, setLoading] = useState();

	const change = () => {
		GTM.send('Valuation', 'initForm');
	};

	const submit = (data) => {
		setLoading('Trwa wysyłanie wiadomości...');

		Http.post(`projects/question/${container.app.id}/${container.app.hash}`, {
			data: {
				question: JSON.stringify({
					...data,
					...props.data,
				}),
				question_content: container.app.extensions.storage.data(),
			},
			success: (response) => {
				setView('thx');
				setContent(response.message);

				GTM.send('Valuation', 'sendForm', { gtm_customer_phone: data.phone, gtm_customer_mail: data.email });

				setLoading(false);
			},
		});
	};

	switch (view) {
		default:
			return (
				<>
					<div className="ts-heading --h5">Chcę otrzymać darmową wycenę</div>

					<div className="ts-content text-tiny text-black/40 mb-8">
						<p>Prosimy o wypełnienie poniższego formularza, aby otrzymać niezobowiązującą wycenę. Nasi doradcy dokładnie przeanalizują projekt Twojego przyszłego ogrodzenia i&nbsp;przygotują ofertę.</p>
					</div>

					<Formik
						initialValues={{
							name: '',
							phone: '',
							email: '',
							message: '',
							agree: false,
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.required('To pole jest wymagane'),
							phone: Yup.string(),
							email: Yup.string()
								.required('To pole jest wymagane')
								.email('To pole wymaga podania prawidłowego adresu e-mail'),
							message: Yup.string(),
							agree: Yup.bool()
								.oneOf([true], 'To pole jest wymagane'),
						})}
						onSubmit={submit}
						render={() => (
							<Form onChange={change}>
								<div className="space-y-5">
									<label>
										<Field name="name" className="ts-input --sh" placeholder="Imię i nazwisko / firma" aria-label="Imię i nazwisko / firma" />
										<span className="ts-input__h">Imię i nazwisko / firma</span>
										<ErrorMessage name="name" component="div" className="error-message" />
									</label>

									<label>
										<Field name="phone" className="ts-input --sh" placeholder="Numer telefonu" aria-label="Numer telefonu" />
										<span className="ts-input__h">Numer telefonu</span>
										<ErrorMessage name="phone" component="div" className="error-message" />
									</label>

									<label>
										<Field name="email" className="ts-input --sh" placeholder="Adres e-mail" aria-label="Adres e-mail" />
										<span className="ts-input__h">Adres e-mail</span>
										<ErrorMessage name="email" component="div" className="error-message" />
									</label>

									<label>
										<Field as="textarea" name="message" className="ts-input --sh --textarea" placeholder="Dodatkowe uwagi" aria-label="Dodatkowe uwagi" />
										<span className="ts-input__h">Dodatkowe uwagi</span>
										<ErrorMessage name="message" component="div" className="error-message" />
									</label>

									<label className="ts-checkbox">
										<Field type="checkbox" name="agree" />
										<span className="ts-content text-xs text-black/40">Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. informuję, iż administratorem Pani/Pana danych osobowych jest Blachotrapez Sp. z o.o. z siedzibą w Rabce-Zdroju, ul.Kilińskiego 49A; kontakt do inspektora ochrony danych: iod@blachotrapez.com.pl; Pełna treść klauzuli informacyjnej dostępna: <a href="https://www.blachotrapez.eu/pl/rodo" rel="nofollow noreferrer" target="_blank">tutaj</a>.</span>
										<ErrorMessage name="agree" component="div" className="error-message" />
									</label>

									<div>
										<button type="submit" className="ts-btn --primary --rounded w-full --sh --lg">
											<span>Wyślij zapytanie</span>
										</button>
									</div>
								</div>
							</Form>
						)}
					/>

					{(loading) && (
						<div className="bg-gray-third" style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
							<Loading text={loading} />
						</div>
					)}
				</>
			);

		case 'thx':
			return (
				<div className="ts-fpan__card bg-blue-50 border border-blue-300 text-center">
					{content}
				</div>
			);
	}
};


ContactForm.defaultProps = {
	data: null,
};

ContactForm.propTypes = {
	data: PropTypes.object,
};


export default ContactForm;