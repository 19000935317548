import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import container from 'redux/container';

import Objects from 'classes/Tools/Objects';


const FieldColorsList = (props) => {
	const input = useRef();
	const [open, setOpen] = useState(false);

	const current = props.options[props.value] || Objects.first(props.options);

	useEffect(() => {
		['blur'].forEach((event) => input.current.addEventListener(event, () => {
			setTimeout(() => {
				setOpen(false);
			}, 250);
		}));
	});

	return (
		<>
			{props.title && (
				<div className="ts-content text-sm">
					<p>{props.title}</p>
				</div>
			)}

			<div onClick={() => { setOpen(true); }} className="ts-select --gray">
				<div className="ts-select__option">
					<span className="ts-select__citem-a" style={{ background: current.type === 'texture' ? `url(${container.app.getSystemDir()}${current.source})` : current.source }} aria-label={current.label} />
					<input type="text" value={current.label} className="ts-select__holder ts-input ts-select --sm --sh" readOnly ref={input} />
				</div>

				<ul
					className={classNames(
						'ts-select__cul',
						!open ? 'hidden' : null,
					)}
				>
					{Objects.entries(props.options).map(([value, p]) => (
						<li onClick={() => props.action(value, p)} key={value}>
							<span className="ts-select__citem-a" style={{ background: p.type === 'texture' ? `url(${container.app.getSystemDir()}${p.source})` : p.source }} aria-label={p.label} />
							<span>{p.label}</span>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};


FieldColorsList.defaultProps = {
	action: null,
	title: null,
	// description: null,
	options: null,
	value: null,
};

FieldColorsList.propTypes = {
	action: PropTypes.func,
	title: PropTypes.string,
	// description: PropTypes.string,
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	value: PropTypes.string,
};


export default FieldColorsList;