import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import container from 'redux/container';
import * as actions from 'modules/App/redux/actions';

import Http from 'classes/Http';
import { withParams } from 'classes/React';

import Template from 'templates/MainTemplate';
import Loading from 'views/Loading';
import Application from '../components/Application';

import Config from '../Config';


class View extends React.Component {
	constructor(props) {
		super(props);

		this.id = this.props.match.params.id ? this.props.match.params.id : '';
		this.hash = this.props.match.params.hash ? this.props.match.params.hash : '';

		this.config = new Config();

		this.state = {
			view: 'loading',
			debug: (container.config.env === 'dev'),
			mode: null,
			path: null,
			email: null,
			project: null,
		};
	}

	componentDidMount = () => {
		if (this.id && this.hash) {
			Http.post(`projects/get/${this.id}/${this.hash}`, {
				success: (response) => {
					if (response.status) {
						try {
							const project = response.project;

							project.data = project.data ? JSON.parse(project.data) : null;

							actions.set('mode', response.mode);

							this.setState({
								view: 'default',
								mode: response.mode,
								path: response.path,
								email: response.email,
								project,
							});
						} catch (Exception) {
							//
						}
					} else {
						this.setState({ view: 'redirect', url: '/' });
					}
				},
			});
		} else {
			this.setState({ view: 'redirect', url: '/' });
		}
	}

	render() {
		switch (this.state.view) {
			default:
				return (
					<Template classMain="--column">
						<Application config={this.config} debug={this.state.debug} id={this.id} hash={this.hash} mode={this.state.mode} path={this.state.path} email={this.state.email} project={this.state.project} />
					</Template>
				);

			case 'redirect':
				return (
					<Navigate to={this.state.url} />
				);

			case 'loading':
				return (
					<Template>
						<Loading />
					</Template>
				);
		}
	}
}


View.defaultProps = {
	match: null,
};

View.propTypes = {
	match: PropTypes.object,
};


const mapStateToProps = (state) => ({ ...state.app, ...state.panel });

export default connect(mapStateToProps)(withParams(View));