let _ = 'APP/';

const GET = `${_}GET`;
const SET = `${_}SET`;

const BAR = `${_}BAR`;
const POPUP = `${_}POPUP`;


export default {
	GET,
	SET,

	BAR,
	POPUP,
};