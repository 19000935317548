import React from 'react';
import PropTypes from 'prop-types';

import container from 'redux/container';
import * as panelActions from '../../../../redux/panel/actions';

import ToolbarMain from '../ToolbarMain';
import ToolbarMainButton from '../ToolbarMain/Button';


class Settings extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.props.object;
	}

	render() {
		return (
			<>
				<ToolbarMain />

				<div className="ts-view__items --b bg-white">
					<div className="grid grid-cols-2 gap-5">
						<ToolbarMainButton action={() => container.app.actions.setAverage()} menu="additional" type="align" description="Wyrównaj przęsła" active={container.app?.settings.average ? 'enabled' : ''} />
						<ToolbarMainButton action={() => container.app.actions.setMode('remove')} menu="additional" type="delete" description="Usuń elementy" active={panelActions.getMode() === 'remove' ? 'active' : ''} />
					</div>
				</div>
			</>
		);
	}
}


Settings.defaultProps = {
	object: null,
};

Settings.propTypes = {
	object: PropTypes.object,
};


export default Settings;