import React from 'react';

import panelReducer from './redux/panel/reducer';

import Project from './views/Project';


export default {
	reducers: {
		panel: panelReducer,
	},
	views: [
		{
			route: { path: 'project/:id/:hash', component: <Project /> },
		},
	],
};