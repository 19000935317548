import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Tooltip = (props) => {
	const [visible, setVisible] = useState(true);
	const [status, setStatus] = useState(true);

	const close = () => {
		setVisible(false);

		setTimeout(() => {
			setStatus(false);
		}, 750);
	};

	if (props.timeout) {
		setTimeout(() => {
			close();
		}, props.timeout * 1000);
	}

	if (status) {
		return (
			<div className="ts-tooltip-init --active">
				<div
					className={classNames(
						'ts-tooltip',
						!props.type === 'left' ? '--lb' : null,
						!props.type === 'right' ? '--rb' : null,
						!visible ? '--close' : null,
					)}
				>
					<p>{props.content}</p>

					<button onClick={() => close()} type="button" className="ts-btnt"><span>{props.close}</span></button>
				</div>
			</div>
		);
	}

	return <></>;
};


Tooltip.defaultProps = {
	type: 'center',
	close: 'ok',
	timeout: 8,
};

Tooltip.propTypes = {
	type: PropTypes.oneOf(['left', 'center', 'right']),
	content: PropTypes.string.isRequired,
	close: PropTypes.string,
	timeout: PropTypes.number,
};


export default Tooltip;