import env from './env';


export default {
	env,
	api: {
		url: (env === 'dev') ? 'http://www.kreatorgard.server.link/' : '/',
		withCredentials: false,
	},
	auth: {
		default: false,
		redirect: '/login/',
	},
	google: 'AIzaSyB7WsU_LXk5L0DUCvYDPs46q4Na_mYQu_U',
};