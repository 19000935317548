import React from 'react';
import PropTypes from 'prop-types';

import * as actions from 'modules/App/redux/actions';


const Modal = (props) => {
	return (
		<div
			tabIndex="-1"
			className="overflow-y-auto overflow-x-hidden bg-black/70 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
			aria-modal="true"
			role="dialog"
		>
			<div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
				<div className="relative bg-white shadow-box rounded dark:bg-gray-700">
					<div className="flex justify-between items-start p-4 px-6 rounded-t border-b dark:border-gray-600">
						{(props.title) && (
							<div className="ts-heading --h5">
								{props.title}
							</div>
						)}

						<button onClick={() => actions.modal(null)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
							<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
							</svg>
						</button>
					</div>

					<div className="p-6 space-y-6 ts-content text-tiny bg-gray-third">
						{props.content}
					</div>
				</div>
			</div>
		</div>
	);
};


Modal.defaultProps = {
	title: false,
};

Modal.propTypes = {
	title: PropTypes.string,
	content: PropTypes.node.isRequired,
};


export default Modal;