import React from 'react';

import container from 'redux/container';

import Objects from 'classes/Tools/Objects';

import FieldSelect from 'templates/MainTemplate/fields/FieldSelect';


class Manufactures extends React.Component {
	title = (p) => {
		let name;

		switch (p.kind) {
			case 'gateSliding':
				name = 'Brama';
				break;

			case 'gateSwing':
				name = 'Brama';
				break;

			case 'wicket':
				name = 'Furtka';
				break;

			default:
		}

		return `${name ? `${name} ` : ''}#${p.id}`;
	}

	accessory = (key) => {
		switch (key) {
			case 'drive':
				return 'Napęd';

			case 'lock':
				return 'Elektrozamek';

			default:
		}

		return '';
	}

	render() {
		return (
			<>
				{Objects.values(container.app.episodes).map((p) => (
					<React.Fragment key={p.id}>
						{(Objects.in(p.kind, ['gateSliding', 'gateSwing', 'wicket'])) && (
							<div className="space-y-3">
								<div className="ts-heading --h5">{this.title(p)}</div>

								{(Objects.in(p.kind, ['gateSliding', 'wicket'])) && (
									<FieldSelect
										action={(value) => p.actions.setConfig('direction', value)}
										title="Kierunek"
										options={{ left: 'Lewa', right: 'Prawa' }}
										value={p.config.direction}
										color="gray"
									/>
								)}

								{(Objects.in(p.kind, ['gateSliding'])) && (
									<FieldSelect
										action={(value) => p.actions.setConfig('side', value)}
										title="Pozycja"
										options={{ in: 'Wewnętrzna', out: 'Zewnętrzna' }}
										value={p.config.side}
										color="gray"
									/>
								)}

								{(container.app.getSystem()[p.kind].accessory) && (
									<>
										{Objects.entries(container.app.getSystem()[p.kind].accessory).map(([key, options]) => (
											<React.Fragment key={p.id}>
												<FieldSelect
													action={(value) => p.actions.setConfig(key, value)}
													title={this.accessory(key)}
													options={options}
													value={p.config[key] || ''}
													color="gray"
												/>
											</React.Fragment>
										))}
									</>
								)}
							</div>
						)}
					</React.Fragment>
				))}
			</>
		);
	}
}


export default Manufactures;