import * as THREE from 'three';
import TextSprite from 'three-textsprite';

import Helper from 'classes/Tools/Helper';

import CuboidGeometry from '../../geometry/CuboidGeometry';
import EpisodeGeometry from '../../geometry/EpisodeGeometry';


class Episode {
	/* --- MESH ----------------------------------------------- */

	doStart = () => {
		this.doStartEnd('start');
	}

	doEnd = () => {
		this.doStartEnd('end');
	}

	doStartEnd = (indicator) => {
		let material = new THREE.MeshFaceMaterial([
			false,
			this.instance.materials.point,
			false,
			false,
			false,
			false,
		]);

		let geometry = new CuboidGeometry(this.instance.font.size, this.udpsi, this.instance.font.size);

		if (!this.objects[indicator]) {
			this.objects[indicator] = new THREE.Mesh(geometry, material);
			this.objects[indicator].appid = this.id;
			this.objects[indicator].appname = indicator;
			this.three.scenes['2d'].add(this.objects[indicator]);
		} else {
			this.objects[indicator].geometry = geometry;
			this.objects[indicator].material = material;
		}

		this.objects[indicator].position.set(this.position[indicator].x, this.instance.dpsi(2) + this.id * this.udpsi, this.position[indicator].z);
		this.objects[indicator].visible = !this.hook[indicator];
	}

	doSketch = () => {
		let material = new THREE.MeshFaceMaterial([
			new THREE.MeshBasicMaterial({ color: this.instance.colors.sketch[this.kind] }),
			false,
			false,
			false,
			false,
			false,

			new THREE.MeshBasicMaterial({ color: this.instance.colors.sketch[this.kind] }),
			false,
			false,
			false,
			false,
			false,

			false,
			false,
			false,
			false,
			false,
			false,

			new THREE.MeshBasicMaterial({ color: 'ff0000' }),
			false,
			false,
			false,
			false,
			false,
		]);

		let geometry = new EpisodeGeometry(
			{
				start: this.data.start,
				end: this.data.end,
				counts: {
					intervals: this.data.counts.intervals,
					poles: this.data.counts.poles,
					walls: this.data.counts.walls,
					panels: this.data.counts.panels,
				},
				pole: {
					width: this.poleMaterial.width,
					height: this.udpsi,
				},
				wall: {
					minWidth: this.system.wall.settings.minWidth,
					width: this.data.wall.width,
					prelast: this.data.wall.prelast,
					last: this.data.wall.last,
					height: this.udpsi,
				},
				sketch: 0.2,
			},
		);

		if (!this.objects.sketch) {
			this.objects.sketch = new THREE.Mesh(geometry, material);
			this.three.scenes['2d'].add(this.objects.sketch);
		} else {
			this.objects.sketch.geometry = geometry;
			this.objects.sketch.material = material;
		}

		this.objects.sketch.position.set(this.position.start.x, this.instance.dpsi(1), this.position.start.z);
		this.objects.sketch.rotation.y = this.data.rotation;
	}

	doDescription = () => {
		if (!this.objects.description) {
			this.objects.description = new TextSprite({
				fontSize: this.instance.font.size,
				fontWeight: this.instance.font.weight,
				fontFamily: this.instance.font.family,
				fillStyle: this.instance.font.color,
			});
			this.three.scenes['2d'].add(this.objects.description);
		}

		this.objects.description.fontSize = this.instance.font.size;
		this.objects.description.material.map.text = `${Helper.numberFormat(this.data.widthFull, 2)} m`;

		this.objects.description.position.set(this.data.center.x, this.instance.dpsi(5), this.data.center.z + 0.015);
	}

	doEpisode = () => {
		let material = new THREE.MeshFaceMaterial([
			// 0
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,

			// 1
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,

			// 2
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,

			// 3
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,

			// 4
			this.instance.getPoleMaterial().type !== 'hollow' ? this.instance.systemMaterials.poles : this.instance.systemMaterials.concretes.tops,
			this.instance.getPoleMaterial().type !== 'hollow' ? this.instance.systemMaterials.poles : this.instance.systemMaterials.concretes.sides,
			this.instance.getPoleMaterial().type !== 'hollow' ? this.instance.systemMaterials.poles : this.instance.systemMaterials.concretes.sides,
			this.instance.getPoleMaterial().type !== 'hollow' ? this.instance.systemMaterials.poles : this.instance.systemMaterials.concretes.sides,
			this.instance.getPoleMaterial().type !== 'hollow' ? this.instance.systemMaterials.poles : this.instance.systemMaterials.concretes.sides,
			this.instance.getPoleMaterial().type !== 'hollow' ? this.instance.systemMaterials.poles : this.instance.systemMaterials.concretes.sides,

			// 5
			this.instance.systemMaterials.holders,
			this.instance.systemMaterials.holders,
			this.instance.systemMaterials.holders,
			this.instance.systemMaterials.holders,
			this.instance.systemMaterials.holders,
			this.instance.systemMaterials.holders,

			// 6
			this.instance.systemMaterials.roofs,
			this.instance.systemMaterials.roofs,
			this.instance.systemMaterials.roofs,
			this.instance.systemMaterials.roofs,
			this.instance.systemMaterials.roofs,
			this.instance.systemMaterials.roofs,

			// 7
			this.instance.systemMaterials.concretes.tops,
			this.instance.systemMaterials.concretes.sides,
			this.instance.systemMaterials.concretes.sides,
			this.instance.systemMaterials.concretes.sides,
			this.instance.systemMaterials.concretes.sides,
			this.instance.systemMaterials.concretes.sides,

			// 8
			this.instance.systemMaterials.errors,
			this.instance.systemMaterials.errors,
			this.instance.systemMaterials.errors,
			this.instance.systemMaterials.errors,
			this.instance.systemMaterials.errors,
			this.instance.systemMaterials.errors,
		]);

		let geometry = new EpisodeGeometry(
			{
				start: this.data.start,
				end: this.data.end,
				counts: {
					intervals: this.data.counts.intervals,
					poles: this.data.counts.poles,
					walls: this.data.counts.walls,
					panels: this.data.counts.panels,
				},
				pole: {
					width: this.poleMaterial.width,
					height: this.instance.settings.height + this.system.settings.space.bottom.pole,
					depth: this.poleMaterial.depth,
					roof: this.poleMaterial.roof,
					base: this.poleMaterial.base,
				},
				wall: {
					minWidth: this.system.wall.settings.minWidth,
					width: this.data.wall.width,
					prelast: this.data.wall.prelast,
					last: this.data.wall.last,
					height: this.data.wall.height,
					space: this.data.wall.space,
					holder: this.wallMaterial.holder,
					frame: this.wallMaterial.frame,
					slat: this.wallMaterial.slat,
					panel: this.wallMaterial.panel,
				},
				half: this.data.half,
				ending: this.data.ending,
				concrete: this.poleMaterial.type !== 'hollow' ? { width: this.poleMaterial.base ? this.poleMaterial.base.width + 0.02 : this.poleMaterial.width * 2, depth: this.poleMaterial.base ? this.poleMaterial.base.depth + 0.02 : this.poleMaterial.depth * 2 } : { width: this.poleMaterial.width, depth: this.poleMaterial.depth },
				mix: this.system.profile.mixes[this.instance.settings.profile.mix],
				manufacture: this.kind !== 'fence',
			},
		);

		if (!this.objects.episode) {
			this.objects.episode = new THREE.Mesh(geometry, material);
			this.objects.episode.castShadow = true;
			this.objects.episode.receiveShadow = false;
			this.three.scenes['3d'].add(this.objects.episode);
		} else {
			this.objects.episode.geometry = geometry;
			this.objects.episode.material = material;
		}

		this.objects.episode.position.set(this.position.start.x, this.position.start.y, this.position.start.z);
		this.objects.episode.rotation.y = this.data.rotation;
	}
}


export default Episode;