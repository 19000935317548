import Objects from 'classes/Tools/Objects';


class LoadScripts {
	constructor(assets, success) {
		this.assets = assets;
		this.success = success;

		this.buffer = 0;

		this.init();
	}

	init = () => {
		if (!window.LoadScripts) {
			window.LoadScripts = [];
		}

		Objects.values(this.assets).forEach((src) => {
			this.add(src);
		});
	}

	add = (src) => {
		this.buffer++;

		if (!Objects.in(src, window.LoadScripts)) {
			let script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.addEventListener('load', () => { this.remove(); });
			document.body.appendChild(script);

			window.LoadScripts.push(src);
		} else {
			this.remove();
		}
	}

	remove = () => {
		this.buffer--;

		if (this.buffer <= 0) {
			if (this.success) {
				this.success();
			}
		}
	}
}


export default LoadScripts;