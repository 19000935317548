import * as THREE from 'three';
import DragControls from 'three-dragcontrols';

import * as panelActions from '../../redux/panel/actions';

import Maths from 'classes/Tools/Maths';

import CuboidGeometry from 'modules/Project/classes/geometry/CuboidGeometry';
import ModelBoxGeometry from 'modules/Project/classes/geometry/ModelBoxGeometry';


class House {
	constructor(instance) {
		this.instance = instance;
		this.three = instance.three;

		this.icon = 1.5;

		// settings
		this.model = { name: 'house1', scale: { x: 12.5, y: 12.5, z: 12.5 } };

		this.active = false;
		this.position = { x: 0, y: 0, z: 0 };
		this.rotation = 0;
		this.scale = 10;
		this.status = false;

		// objects
		this.objects = {
			sketch: null,
			rotation: null,
			scale: null,
			model: null,
		};

		this.lock = {
			rotation: { status: false },
			scale: { status: false, sensitivity: 2 },
			model: null,
		};

		this.do();
	}

	do = () => {
		this.doSketch();
		this.doRotation();
		this.doScale();

		if (!this.instance.isMoving) {
			this.doModel();
		}
	}

	doSketch = () => {
		let material = new THREE.MeshFaceMaterial([
			false,
			new THREE.MeshBasicMaterial({ color: this.instance.colors.house, transparent: true, opacity: 0.6 }),
			false,
			false,
			false,
			false,
		]);

		let geometry = new CuboidGeometry(1, this.instance.dpsi(2), 1);

		if (!this.objects.sketch) {
			this.objects.sketch = new THREE.Mesh(geometry, material);
			this.three.scenes['2d'].add(this.objects.sketch);

			this.eventsSketch(this.objects.sketch);
		} else {
			this.objects.sketch.geometry = geometry;
			this.objects.sketch.material = material;
		}

		this.objects.sketch.position.set(this.position.x, 0, this.position.z);
		this.objects.sketch.rotation.y = this.rotation;
		this.objects.sketch.scale.set(this.scale, 1, this.scale);
		this.objects.sketch.visible = this.status;
	}

	doRotation = () => {
		let material = new THREE.MeshFaceMaterial([
			false,
			this.lock.rotation.status ? this.instance.materials.rotationactive : this.instance.materials.rotation,
			false,
			false,
			false,
			false,
		]);

		let geometry = new CuboidGeometry(this.icon, this.instance.dpsi(2, 1), this.icon);

		if (!this.objects.rotation) {
			this.objects.rotation = new THREE.Mesh(geometry, material);
			this.three.scenes['2d'].add(this.objects.rotation);

			this.eventsRotation(this.objects.rotation);
		} else {
			this.objects.rotation.geometry = geometry;
			this.objects.rotation.material = material;
		}

		const diff = Maths.rotatePoint({ x: (this.active ? this.objects.sketch.scale.x : this.scale) / 2 + this.icon / 2, y: 0, z: (this.active ? this.objects.sketch.scale.z : this.scale) / 2 + this.icon / 2 }, this.active ? this.objects.sketch.rotation.y : this.rotation);

		this.objects.rotation.position.set(this.position.x + diff.x, 0, this.position.z + diff.z);
		this.objects.rotation.rotation.y = this.active ? this.objects.sketch.rotation.y : this.rotation;
		this.objects.rotation.visible = this.status && this.active;
	}

	doScale = () => {
		let material = new THREE.MeshFaceMaterial([
			false,
			this.lock.scale.status ? this.instance.materials.scaleactive : this.instance.materials.scale,
			false,
			false,
			false,
			false,
		]);

		let geometry = new CuboidGeometry(this.icon, this.instance.dpsi(2, 1), this.icon);

		if (!this.objects.scale) {
			this.objects.scale = new THREE.Mesh(geometry, material);
			this.three.scenes['2d'].add(this.objects.scale);

			this.eventsScale(this.objects.scale);
		} else {
			this.objects.scale.geometry = geometry;
			this.objects.scale.material = material;
		}

		const diff = Maths.rotatePoint({ x: (this.active ? this.objects.sketch.scale.x : this.scale) / 2 + this.icon / 2, y: 0, z: -(this.active ? this.objects.sketch.scale.z : this.scale) / 2 - this.icon / 2 }, this.active ? this.objects.sketch.rotation.y : this.rotation);

		this.objects.scale.position.set(this.position.x + diff.x, 0, this.position.z + diff.z);
		this.objects.scale.rotation.y = this.active ? this.objects.sketch.rotation.y : this.rotation;
		this.objects.scale.visible = this.status && this.active;
	}

	doModel = () => {
		if (this.status) {
			if (this.lock.model !== this.model.name) {
				this.lock.model = this.model.name;

				this.three.gltf.load(`/assets/models/${this.model.name}.gltf`, (gltf) => {
					if (this.objects.model) {
						this.three.scenes['3d'].remove(this.objects.model);
					}

					this.objects.model = gltf.scene;

					this.objects.model.traverse((o) => {
						if (o instanceof THREE.Mesh) {
							o.castShadow = true;
							o.receiveShadow = false;

							switch (o.name) {
								case 'windows':
									o.material = this.instance.materials.glass;
									break;

								case 'barrels':
								case 'gutters':
								case 'ridge':
								case 'sills':
								case 'windows_frames_roof':
									o.material = this.instance.materials.black;
									break;

								case 'garage':
								case 'windows_frames':
									// o.material = this.instance.systemMaterials.profiles.mixes;
									break;

								default:
									o.material.metalness = 0;
									o.material.roughness = 1;
									break;
							}
						}
					});

					this.three.scenes['3d'].add(this.objects.model);

					this.doModel();
				});
			} else if (this.objects.model) {
				this.objects.model.traverse((o) => {
					if (o instanceof THREE.Mesh) {
						switch (o.name) {
							case 'garage':
							case 'windows_frames':
								// o.material = this.instance.systemMaterials.profiles.mixes;
								break;

							default:
						}
					}
				});

				this.objects.model.position.set(this.position.x, 0, this.position.z);
				this.objects.model.rotation.y = this.rotation;
				this.objects.model.scale.set(this.scale / this.model.scale.x, this.scale / this.model.scale.y, this.scale / this.model.scale.z);
				this.objects.model.visible = this.status;
			}
		} else if (this.objects.model) {
			this.objects.model.visible = false;
		}
	}

	doModelBox = () => {
		let material = new THREE.MeshLambertMaterial({ color: 0xa0a0a0, transparent: true, opacity: 0.8 });

		let geometry = new ModelBoxGeometry(1, 0.5, 1);

		if (!this.objects.model) {
			this.objects.model = new THREE.Mesh(geometry, material);
			this.objects.model.castShadow = true;
			this.objects.model.receiveShadow = false;
			this.three.scenes['3d'].add(this.objects.model);
		} else {
			this.objects.model.geometry = geometry;
			this.objects.model.material = material;
		}

		this.objects.model.position.set(this.position.x, 0, this.position.z);
		this.objects.model.rotation.y = this.rotation;
		this.objects.model.scale.set(this.scale, this.scale, this.scale);
		this.objects.model.visible = this.status;
	}


	/* --- EVENTS --------------------------------------------- */

	eventsSketch = (o) => {
		o.drag = new DragControls([o], { moveable: false }, this.three.cameras['2d'], this.three.render.domElement);

		['click', 'touchend'].forEach((event) => o.drag.addEventListener(event, () => {
			if (panelActions.getMode() === 'remove' && !this.instance.raycaster.current) {
				this.remove();
			}
		}));

		['drag'].forEach((event) => o.drag.addEventListener(event, (e) => {
			this.position.x = e.object.position.x;
			this.position.z = e.object.position.z;

			this.do();
		}));
	}

	eventsRotation = (o) => {
		const canvas = this.instance.canvas[0].children[0];

		['mousedown'].forEach((event) => o.on(event, () => {
			this.lock.rotation.status = true;

			this.doRotation();
		}));

		['mousemove'].forEach((event) => canvas.addEventListener(event, (e) => {
			if (this.lock.rotation.status) {
				const point = this.three.view.point(e);

				if (point) {
					const rotation = (Math.atan2(point.z - this.position.z, point.x - this.position.x) + Math.PI / 4) * -1;

					this.objects.sketch.rotation.y = rotation;

					this.doRotation();
					this.doScale();
				}
			}
		}));

		['mouseup'].forEach((event) => canvas.addEventListener(event, () => {
			if (this.lock.rotation.status) {
				this.lock.rotation.status = false;

				this.apply();
			}
		}));

		['mouseleave'].forEach((event) => canvas.addEventListener(event, () => {
			if (this.lock.rotation.status) {
				this.lock.rotation.status = false;

				this.do();
			}
		}));
	}

	eventsScale = (o) => {
		const canvas = this.instance.canvas[0].children[0];

		['mousedown'].forEach((event) => o.on(event, (e) => {
			this.lock.scale.status = true;

			const point = this.three.view.point(e.data.originalEvent);

			if (point) {
				this.lock.scale.value = this.objects.sketch.scale.x;
				this.lock.scale.direction = { x: point.x >= this.position.x ? 1 : -1, z: point.z >= this.position.z ? 1 : -1 };
				this.lock.scale.position = { x: point.x, z: point.z };

				this.doScale();
			}
		}));

		['mousemove'].forEach((event) => canvas.addEventListener(event, (e) => {
			if (this.lock.scale.status) {
				const point = this.three.view.point(e);

				if (point) {
					const move = ((this.lock.scale.position.x - point.x) * this.lock.scale.direction.x + (this.lock.scale.position.z - point.z) * this.lock.scale.direction.z) / this.lock.scale.sensitivity * -1;

					let scale = this.lock.scale.value + move;
					if (scale < 4) scale = 4;
					if (scale > 50) scale = 50;

					this.objects.sketch.scale.x = scale;
					this.objects.sketch.scale.z = scale;

					this.doRotation();
					this.doScale();
				}
			}
		}));

		['mouseup'].forEach((event) => canvas.addEventListener(event, () => {
			if (this.lock.scale.status) {
				this.lock.scale.status = false;

				this.apply();
			}
		}));

		['mouseleave'].forEach((event) => canvas.addEventListener(event, () => {
			if (this.lock.scale.status) {
				this.lock.scale.status = false;

				this.do();
			}
		}));
	}


	/* --- METHODS -------------------------------------------- */

	activate = (add) => {
		if (add) {
			this.objects.sketch.material[1].opacity = 0.3;
			this.objects.sketch.position.y = -100;
			this.objects.sketch.visible = true;

			// ghost
			this.instance.raycaster.ghost = this.objects.sketch;
		} else {
			this.active = true;

			this.do();
		}

		// events
		if (this.objects.sketch) {
			this.objects.sketch.drag.setOption('moveable', true);
		}
	}

	deactivate = () => {
		this.active = false;

		this.do();

		// events
		if (this.objects.sketch) {
			this.objects.sketch.drag.setOption('moveable', false);
		}
	}

	reset = () => {
		// ghost
		this.instance.raycaster.ghost = null;

		this.do();
	}

	apply = () => {
		this.active = true;
		this.position = { x: this.objects.sketch.position.x, y: this.objects.sketch.position.y, z: this.objects.sketch.position.z };
		this.rotation = this.objects.sketch.rotation.y;
		this.scale = this.objects.sketch.scale.x;
		this.status = true;

		this.reset();

		this.instance.setModified();
	}

	remove = () => {
		this.active = false;
		this.position = { x: 0, y: 0, z: 0 };
		this.rotation = 0;
		this.scale = 10;
		this.status = false;

		this.reset();

		this.instance.setModified();
	}
}


export default House;