import * as THREE from 'three';
import TextSprite from 'three-textsprite';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';

import Helper from 'classes/Tools/Helper';
import Maths from 'classes/Tools/Maths';
import Objects from 'classes/Tools/Objects';


class ThreeRaycaster {
	constructor(instance, three, args) {
		this.instance = instance;
		this.three = three;

		this.status = args.status !== undefined ? args.status : true; // włącz/wyłącz cały mechanizm
		this.mode = args.mode; // click || select || scope || draw
		this.button = args.button || 1; // 1 (left) || 2 (right) || 3 (double)
		this.canvas = args.canvas;
		this.workspace = args.workspace;
		this.intersects = args.intersects;
		this.ghost = args.ghost;
		this.min = args.min || false;
		this.max = args.max || false;

		this.raycaster = new THREE.Raycaster();
		this.pointer = new THREE.Vector2();

		// objects
		this.objects = {
			line: null,
			description: null,
		};

		this.lock = false; // zablokowany z powodu limitów
		this.click = false; // mouseup działa po mousedown
		this.during = false; // w trakcie rysowania
		this.scoping = false; // w trakcie akcji scope

		this.current = null; // złapany element z this.intersects
		this.hook = { start: null, end: null }; // złapane elementy

		this.layerX = 0;
		this.layerY = 0;
		this.position = { x: 0, y: 0, z: 0 };
		this.start = { x: 0, y: 0, z: 0 };
		this.end = { x: 0, y: 0, z: 0 };

		this.data = {
			width: 0,
			center: { x: 0, y: 0, z: 0 },
		};

		this.init();

		// events
		this.hotkeys();
		this.mouseMove();
		this.mouseDown();
		this.mouseUp();
	}

	init = (intersects = null) => {
		if (intersects) {
			this.intersects = intersects;
		}

		this.intersects.push(this.workspace);
	}

	verify = () => {
		let focus = false;

		if (this.data.widthInner < this.min) {
			focus = this.min;
		}

		if (this.data.widthInner > this.max) {
			focus = this.max;
		}

		if (focus) {
			const A = { x: this.start.x, y: this.start.z };
			const B = { x: this.position.x, y: this.position.z };

			const rotation = Maths.getRotation(A, B);
			const point = Maths.rotatePoint({ x: focus + this.data.diff, y: 0, z: 0 }, rotation);
			const position = { x: this.start.x + point.x, y: this.start.y + point.y, z: this.start.z + point.z };

			const width = Maths.getDistance(A, { x: position.x, y: position.z });
			const widthCustom = this.data.widthCustom - this.data.widthInner + focus;
			const widthInner = focus;
			const widthFull = this.data.widthFull - this.data.widthInner + focus;
			const center = Maths.getCenter(A, { x: position.x, y: position.z });

			this.data.width = width;
			this.data.widthCustom = widthCustom;
			this.data.widthInner = widthInner;
			this.data.widthFull = widthFull;
			this.data.rotation = rotation;
			this.data.center = { x: center.x, y: 0, z: center.y };
			this.position = position;

			this.lock = true;

			this.dispatchState();
		} else {
			this.lock = false;
		}
	}

	filter = (position) => {
		const A = { x: position[0].x, y: position[0].z };
		const B = { x: position[1].x, y: position[1].z };

		const diff = 0;
		const width = Maths.getDistance(A, B);
		const widthCustom = width;
		const widthInner = width;
		const widthFull = width;
		const rotation = Maths.getRotation(A, B);
		const center = Maths.getCenter(A, B);

		return {
			diff,
			width,
			widthCustom,
			widthInner,
			widthFull,
			rotation,
			center: { x: center.x, y: 0, z: center.y },
		};
	}

	calcData = () => {
		const position = [this.start, this.current ? this.current.position : this.position];
		const hook = [this.hook.start, this.current];

		return this.filter(position, hook);
	}


	/* --- OBJECTS -------------------------------------------- */

	doLine = () => {
		let material = new LineMaterial({ color: this.instance.colors.line, linewidth: 5 });
		material.resolution.set(window.innerWidth, window.innerHeight);

		let geometry = new LineGeometry();
		geometry.setPositions([this.start.x, this.start.y, this.start.z, this.position.x, this.position.y, this.position.z]);

		if (!this.objects.line) {
			this.objects.line = new Line2(geometry, material);
			this.objects.line.position.set(0, this.instance.dpsi(1, 1), 0);
			this.three.scenes['2d'].add(this.objects.line);
		} else {
			this.objects.line.geometry = geometry;
			this.objects.line.material = material;
		}

		this.objects.line.visible = this.during;
	}

	doDescription = () => {
		if (!this.objects.description) {
			this.objects.description = new TextSprite({
				fontSize: this.instance.font.size,
				fontWeight: this.instance.font.weight,
				fontFamily: this.instance.font.family,
				fillStyle: '#ffffff',
			});
			this.three.scenes['2d'].add(this.objects.description);
		}

		this.objects.description.fontSize = this.instance.font.size;
		this.objects.description.material.map.text = `${Helper.numberFormat(this.data.widthFull, 2)} m`;

		this.objects.description.position.set(this.data.center.x, this.instance.dpsi(5), this.data.center.z + 0.015);
		this.objects.description.visible = this.during;
	}


	/* --- EVENTS --------------------------------------------- */

	hotkeys = () => {
		['keydown'].forEach((event) => document.addEventListener(event, (e) => {
			if (!this.status || !this.mode) return;

			if (e.keyCode === 27) {
				if (Objects.in(this.mode, ['scope', 'draw']) && this.during) {
					this.break();
				}
			}
		}));
	}

	mouseMove = () => {
		['mousemove'].forEach((event) => this.canvas.addEventListener(event, (e) => {
			if (!this.status || !this.mode) return;

			// mouse position
			if (!(e.clientX === -1 && e.clientY === -1)) {
				this.layerX = e.layerX;
				this.layerY = e.layerY;
			}

			// pointer
			const x = (this.layerX / this.three.width) * 2 - 1;
			const y = -(this.layerY / this.three.height) * 2 + 1;

			this.pointer.set(x, y);

			// raycaster
			this.raycaster.setFromCamera(this.pointer, this.three.camera);

			// intersects
			const intersects = this.raycaster.intersectObjects(this.intersects);

			if (intersects.length) {
				const intersect = intersects[0];
				const position = intersect.point;

				this.position = position;

				if (this.ghost) {
					this.ghost.position.set(position.x, 0, position.z);
				}

				if (Objects.in(this.mode, ['select', 'scope', 'draw'])) {
					if (!this.lock && intersect.object && intersect.object !== this.workspace) {
						if (intersect.object !== this.current) {
							if (this.current && this.unhover) {
								this.unhover(this.current);
							}

							this.current = intersect.object;

							if (this.current && this.hover) {
								this.hover(this.current);
							}
						}
					} else {
						if (this.current && this.unhover) {
							this.unhover(this.current);
						}

						this.current = null;
					}
				}
			}

			// line
			if (Objects.in(this.mode, ['scope', 'draw']) && this.during) {
				this.data = this.calcData();

				this.verify();

				this.doLine();
				this.doDescription();
			}
		}));

		['mouseleave'].forEach((event) => this.canvas.addEventListener(event, () => {
			if (!this.status || !this.mode) return;

			this.break();
		}));
	}

	mouseDown = () => {
		['mousedown'].forEach((event) => this.canvas.addEventListener(event, (e) => {
			if (!this.status || !this.mode) return;
			if (e.buttons !== this.button) return;

			this.click = true;

			if (Objects.in(this.mode, ['click'])) {
				this.during = true;
				this.start = this.position;
			}

			if (Objects.in(this.mode, ['select', 'draw'])) {
				this.during = true;
				this.start = this.position;

				if (this.current) {
					this.hook.start = this.current;
					this.start = this.current.position;
				}
			}

			if (Objects.in(this.mode, ['scope'])) {
				if (!this.scoping) {
					this.during = true;
					this.scoping = true;
					this.start = this.position;

					if (this.current) {
						this.hook.start = this.current;
						this.start = this.current.position;
					}
				}
			}
		}));
	}

	mouseUp = () => {
		['mouseup'].forEach((event) => this.canvas.addEventListener(event, () => {
			if (!this.status || !this.mode) return;
			if (!this.click) return;

			this.click = false;
			this.end = this.position;

			if (Objects.in(this.mode, ['select', 'draw']) && this.current) {
				this.hook.end = this.current;
				this.end = this.current.position;
			}

			if (Objects.in(this.mode, ['scope']) && this.current) {
				if (this.start !== this.current.position) {
					this.hook.end = this.current;
				}

				this.end = this.current.position;
			}

			switch (this.mode) {
				case 'click':
				case 'select':
					if (this.start !== this.end) {
						this.during = false;
						return;
					}
					break;

				case 'scope':
					if (this.start !== this.end) {
						this.scoping = false;
					}
					break;

				case 'draw':
					if (this.start === this.end) {
						this.during = false;
						return;
					}
					break;

				default:
			}

			if (Objects.in(this.mode, ['click', 'draw'])) {
				if (this.during) {
					this.dispatchSuccess();
				}

				this.break();
			}

			if (Objects.in(this.mode, ['select'])) {
				if (this.during && this.hook.end) {
					this.dispatchSuccess();
				}

				this.break();
			}

			if (Objects.in(this.mode, ['scope'])) {
				if (!this.scoping) {
					this.dispatchSuccess();
					this.break();
				}
			}
		}));
	}


	/* --- METHODS -------------------------------------------- */

	getStatus = () => this.status

	setStatus = (status) => {
		this.status = status;
	}

	getMode = () => this.mode

	setMode = (mode) => {
		this.mode = mode;
	}

	dispatchState = () => {
		setTimeout(() => {
			this.canvas.dispatchEvent(new MouseEvent('mousemove', { clientX: -1, clientY: -1 }));
		}, 50);
	}

	dispatchSuccess = () => {
		if (this.success) {
			this.success([this.start, this.end], [this.hook.start, this.hook.end]);

			this.dispatchState();
		}
	}

	break = () => {
		this.lock = false;
		this.click = false;
		this.during = false;
		this.scoping = false;
		this.hook = { start: null, end: null };

		if (Objects.in(this.mode, ['scope', 'draw'])) {
			this.doLine();
			this.doDescription();
		}
	}
}


export default ThreeRaycaster;