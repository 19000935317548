import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Menu = (props) => {
	return (
		<nav
			className={classNames(
				'ts-nav',
				!props.visible ? 'hidden' : null,
			)}
		>
			<div className="ts-nav__wp">
				<div className="container lg:px-0 lg:max-w-none">
					<div className="space-y-10 2xl:space-y-20">
						<ul>
							<li>
								<a href="https://www.gardsystems.eu/">Home</a>
							</li>
							<li>
								<a href="https://www.gardsystems.eu/system">Systemy</a>
							</li>
							<li>
								<a href="https://www.gardsystems.eu/galeria">Galeria</a>
							</li>
							<li>
								<a href="https://www.gardsystems.eu/siec-sprzedazy">Sieć sprzedaży</a>
							</li>
							<li>
								<a href="https://www.gardsystems.eu/do-pobrania">Do pobrania</a>
							</li>
							<li>
								<a href="https://www.gardsystems.eu/kontakt">Kontakt</a>
							</li>
						</ul>

						<div>
							<a href="/" className="ts-btn --light --wide">
								<span>Konfigurator ogrodzeń</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};


Menu.defaultProps = {
	visible: false,
};

Menu.propTypes = {
	visible: PropTypes.bool,
};


export default Menu;