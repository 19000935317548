import Helper from 'classes/Tools/Helper';
import Maths from 'classes/Tools/Maths';
import Objects from 'classes/Tools/Objects';

import EpisodeActions from './EpisodeActions';
import EpisodeMesh from './EpisodeMesh';

import Manufacture from '../Manufacture';


class Episode extends EpisodeMesh {
	constructor(instance, id, kind, hook, position, config) {
		super();

		this.instance = instance;
		this.three = instance.three;
		this.actions = new EpisodeActions(instance, this);

		this.udpsi = 0.002;

		this.kind = kind;
		this.hook = hook;
		this.position = {
			start: { x: position.start.x, y: 0, z: position.start.z },
			end: { x: position.end.x, y: 0, z: position.end.z },
		};
		this.config = config;

		// system
		this.system = this.instance.getSystem();

		this.poleMaterial = this.setPoleMaterial();
		this.wallMaterial = this.setWallMaterial();

		// settings
		this.id = id;
		this.prev = this.instance.lastEpisode;
		this.next = this.instance.firstEpisode;

		this.data = this.calcData();

		// objects
		this.objects = {
			start: null,
			end: null,
			sketch: null,
			description: null,
			episode: null,
		};

		this.do();

		// targets
		this.manufacture = new Manufacture(this.instance, this);

		// raycaster intersects
		this.instance.intersects.push(this.objects.start);
		this.instance.intersects.push(this.objects.end);
	}

	setPoleMaterial = () => Objects.merge(this.system.profile.materials[this.instance.settings.profile.material], this.system.pole.materials[this.instance.settings.pole.material])

	setWallMaterial = () => Objects.merge(this.system.profile.materials[this.instance.settings.profile.material], this.system.wall.materials[this.instance.settings.wall.material])

	calcData = () => {
		const data = this.instance.getEpisodeData([this.position.start, this.position.end], [this.hook.start, this.hook.end]);

		let start = !this.hook.start ? 1 : 0;
		let end = !this.hook.end ? 1 : 0;

		let {
			diff,
			width,
			widthCustom,
			widthInner,
			widthFull,
			rotation,
			center,
		} = data;

		// counts
		let intervals = this.kind === 'fence' ? Math.floor((widthCustom - (start + end) * this.poleMaterial.width + this.poleMaterial.width) / (this.system.wall.settings.maxWidth + this.poleMaterial.width)) : 0;
		let poles = intervals + start + end;
		let walls = intervals + 1;
		let panels = Math.floor(0.001 + (this.instance.settings.height - this.system.settings.space.bottom.wall - Helper.aN(this.wallMaterial.frame?.height) * 2 + this.wallMaterial.panel.space.wall) / (this.wallMaterial.panel.height + this.wallMaterial.panel.space.wall));

		// wall
		let wallWidth = this.system.wall.settings.maxWidth;
		let wallPrelast = wallWidth;
		let wallLast = widthCustom - (poles * this.poleMaterial.width + intervals * wallWidth);
		let wallHeight = this.wallMaterial.panel.height * panels + this.wallMaterial.panel.space.wall * (panels - 1) + Helper.aN(this.wallMaterial.frame?.height) * 2;
		let wallSpace = this.system.settings.space.bottom.pole + this.system.settings.space.bottom.wall;

		if (!this.instance.settings.average) {
			if (walls >= 2 && wallLast < this.system.wall.settings.minWidth) {
				wallPrelast = (wallPrelast + wallLast) / 2;
				wallLast = wallPrelast;
			}
		} else {
			if (walls >= 2) {
				wallWidth = (widthCustom - poles * this.poleMaterial.width) / walls;
				wallPrelast = wallWidth;
				wallLast = wallWidth;
			}
		}

		let corner = {
			start: 0,
			end: 0,
		};

		if (this.poleMaterial.type === 'hollow') {
			if (this.hook.start) {
				const hook = this.instance.episodes[this.hook.start.id];

				if (hook) {
					if (Math.abs(rotation - hook.data.rotation) >= this.instance.cornerTolerance) {
						corner.start = 1;
					}
				}
			}

			if (this.hook.end) {
				const hook = this.instance.episodes[this.hook.end.id];

				if (hook) {
					if (Math.abs(rotation - hook.data.rotation) >= this.instance.cornerTolerance) {
						corner.end = 1;
					}
				}
			}
		}

		let half = {
			start: !!corner.start,
			end: !!corner.end,
		};

		if (this.poleMaterial.type === 'hollow') {
			Objects.values(this.instance.episodes).forEach((p) => {
				if (p.hook.start && p.hook.start.id === this.id && Math.abs(rotation - p.data.rotation) >= this.instance.cornerTolerance) {
					half[p.hook.start.name] = true;
				}

				if (p.hook.end && p.hook.end.id === this.id && Math.abs(rotation - p.data.rotation) >= this.instance.cornerTolerance) {
					half[p.hook.end.name] = true;
				}
			});
		}

		let ending = {
			start: false,
			end: false,
		};

		if (this.kind === 'fence') {
			Objects.values(this.instance.episodes).forEach((p) => {
				Objects.values(['start', 'end']).forEach((indicator) => {
					if (p.kind !== 'fence' && p.hook[indicator] && p.hook[indicator].id === this.id) {
						ending[p.hook[indicator].name] = true;
					}
				});
			});
		} else {
			ending.start = true;
			ending.end = true;
		}

		return {
			start: start || corner.start,
			end: end || corner.end,
			diff,
			width,
			widthCustom,
			widthInner,
			widthFull,
			rotation,
			center,
			counts: {
				intervals,
				poles: poles + corner.start + corner.end,
				walls,
				panels,
			},
			wall: {
				width: wallWidth,
				prelast: wallPrelast,
				last: wallLast,
				height: wallHeight,
				space: wallSpace,
			},
			corner,
			half,
			ending,
		};
	}

	do = () => {
		this.doStart();
		this.doEnd();
		this.doSketch();
		this.doDescription();

		if (!this.instance.isMoving) {
			this.doEpisode();
		}
	}

	reload = () => {
		this.poleMaterial = this.setPoleMaterial();
		this.wallMaterial = this.setWallMaterial();

		this.repair();

		this.data = this.calcData();

		this.do();

		// targets
		this.manufacture.update();
	}

	repair = () => {
		if (Objects.in(this.kind, ['gateSliding', 'gateSwing', 'wicket'])) {
			const focus = parseFloat(this.config.width);

			const A = { x: this.position.start.x, y: this.position.start.z };
			const B = { x: this.position.end.x, y: this.position.end.z };

			const rotation = Maths.getRotation(A, B);
			const point = Maths.rotatePoint({ x: focus + this.poleMaterial.width, y: 0, z: 0 }, rotation);
			const position = { x: this.position.start.x + point.x, y: this.position.start.y + point.y, z: this.position.start.z + point.z };

			this.position.end = position;

			Objects.values(this.instance.episodes).forEach((p) => {
				Objects.values(['start', 'end']).forEach((indicator) => {
					if (p.hook[indicator] && p.hook[indicator].id === this.id && p.hook[indicator].name === 'end') {
						p.position[indicator] = position;
					}
				});
			});
		}
	}

	remove = () => {
		this.three.scenes['2d'].remove(this.objects.start);
		this.three.scenes['2d'].remove(this.objects.end);
		this.three.scenes['2d'].remove(this.objects.sketch);
		this.three.scenes['2d'].remove(this.objects.description);
		this.three.scenes['3d'].remove(this.objects.episode);

		// targets
		this.manufacture.remove();
	}
}


export default Episode;