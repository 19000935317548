import React from 'react';

import container from 'redux/container';
import * as panelActions from '../../../../redux/panel/actions';

import Objects from 'classes/Tools/Objects';

import FieldInput from 'templates/MainTemplate/fields/FieldInput';
import Button from './Button';


const ToolbarMain = () => {
	const mode = panelActions.getMode();
	const system = container.app.getSystem();

	return (
		<div className="ts-view__items --sh --grow">
			<div className="ts-heading --h5">Wstaw</div>

			<div className="grid grid-cols-2 gap-5">
				<Button action={() => container.app.actions.setMode('episode')} menu="default" type="design" description="przęsła i&nbsp;słupki" active={mode === 'episode' ? 'active' : ''} />
				<Button action={() => container.app.actions.setMode('gateSliding')} menu="default" type="bridge" description="bramę przesuwną" active={mode === 'gateSliding' ? 'active' : ''} />
				<Button action={() => container.app.actions.setMode('gateSwing')} menu="default" type="bridge" description="bramę dwuskrzydłową" active={mode === 'gateSwing' ? 'active' : ''} />
				<Button action={() => container.app.actions.setMode('wicket')} menu="default" type="door" description="furtkę" active={mode === 'wicket' ? 'active' : ''} />
				<Button action={() => container.app.actions.setMode('house')} menu="default" type="house" description="dom" active={mode === 'house' ? 'active' : ''} />
			</div>

			{(Objects.in(mode, ['gateSliding', 'gateSwing', 'wicket'])) && (
				<FieldInput
					action={(value) => container.app.actions.setWidth(value)}
					title="Szerokość"
					value={container.app.settings.width}
					type="float"
					min={system[mode].settings.width.min}
					max={system[mode].settings.width.max}
				/>
			)}
		</div>
	);
};


export default ToolbarMain;