import * as panelActions from '../redux/panel/actions';

import Helper from 'classes/Tools/Helper';
import Maths from 'classes/Tools/Maths';
import Objects from 'classes/Tools/Objects';


class Actions {
	constructor(instance) {
		this.instance = instance;
		this.three = instance.three;
	}

	setPanel = (type) => {
		switch (type) {
			case 'settings':
				panelActions.set(type, this.instance.settings, this.instance.settings);
				break;

			default:
		}
	}

	setSetting = (name, value) => {
		this.instance.settings[name] = value;

		this.instance.setModified();
		this.setPanel('settings');
	}

	setSystemSetting = (indicator, option, value) => {
		this.instance.settings[indicator][option] = value;

		switch (option) {
			case 'color':
				this.instance.updateTextures(true);

				this.instance.objects.house.doModel();
				break;

			default:
				this.instance.projectReload();
				break;
		}

		this.instance.setModified();
		this.setPanel('settings');
	}

	setMode = (mode) => {
		// limits
		this.instance.raycaster.min = false;
		this.instance.raycaster.max = false;

		// house
		this.instance.objects.house.deactivate();

		switch (mode) {
			case 'episode':
				this.instance.raycaster.setMode('scope');
				break;

			case 'gateSliding':
			case 'gateSwing':
			case 'wicket':
				this.instance.raycaster.setMode('scope');

				// limits
				const { width } = this.instance.getSystem()[mode];

				this.instance.raycaster.min = width;
				this.instance.raycaster.max = width;
				break;

			case 'house':
				if (!this.instance.objects.house.status) {
					this.instance.raycaster.setMode('click');

					this.instance.objects.house.activate(true);
				} else {
					this.instance.raycaster.setMode(false);

					this.instance.objects.house.activate(false);
				}
				break;

			case 'remove':
				this.instance.raycaster.setMode('select');
				break;

			default:
		}

		if (mode !== panelActions.getMode() && Objects.in(mode, ['gateSliding', 'gateSwing', 'wicket'])) {
			this.setWidth(Helper.numberFormat(this.instance.getSystem()[mode].settings.width.default, 2, '.'));
		}

		panelActions.setMode(mode);
	}

	setHeight = (multiplier) => {
		let height = this.instance.settings.height + (this.instance.getSystem().settings.stepHeight * multiplier);

		if (height < this.instance.getSystem().settings.minHeight) height = this.instance.getSystem().settings.minHeight;
		if (height > this.instance.getSystem().settings.maxHeight) height = this.instance.getSystem().settings.maxHeight;

		this.instance.settings.height = Maths.round(height, 2);
		this.instance.projectReload();

		this.instance.setModified();
		this.setPanel('settings');
	}

	setAverage = () => {
		this.instance.settings.average = !this.instance.settings.average;
		this.instance.projectReload();

		this.instance.setModified();
		this.setPanel('settings');
	}

	setWidth = (value) => {
		this.instance.raycaster.min = parseFloat(value);
		this.instance.raycaster.max = parseFloat(value);

		this.setSetting('width', value);
	}

	clear = () => {
		if (window.confirm('Usunąć wszystkie elementy?')) {
			// episodes
			Objects.values(this.instance.episodes).forEach((p) => {
				this.instance.removeEpisode(p.id);
			});

			this.instance.setModified();
			this.setPanel('settings');
		}
	}

	switchGround = () => {
		const variants = ['grass', 'satelite'];

		const current = Objects.keys(variants).find((key) => variants[key] === this.instance.settings.ground);
		const next = parseInt(current, 10) + 1;
		const ground = variants[current !== undefined && next < variants.length ? next : 0];

		variants.forEach((id) => {
			if (this.three.environment.objects[id]) {
				this.three.environment.objects[id].visible = id === ground;
			}
		});

		this.instance.settings.ground = ground;
	}

	switchView = (view) => {
		if (view) {
			this.instance.setView(view);
		} else {
			if (this.three.view.get() === '2d') {
				this.instance.setView('3d');
			} else {
				this.instance.setView('2d');
			}
		}
	}
}


export default Actions;