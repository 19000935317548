import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import container from 'redux/container';
import * as actions from 'modules/App/redux/actions';
import * as panelActions from '../../../redux/panel/actions';

import Toolbar from './Content/Toolbar';
import Settings from './Content/Settings';


const Panel = (props) => {
	const content = () => {
		switch (props.type) {
			case 'settings':
				if (panelActions.getView() === '2d') {
					return (<Toolbar object={props.object} />);
				}

				if (panelActions.getView() === '3d') {
					return (<Settings object={props.object} />);
				}
				break;

			default:
		}

		return <></>;
	};

	return (
		<div
			className={classNames(
				'ts-view',
				!actions.get('bar') ? 'slide-inleft' : 'slide-inright',
				actions.get('barClicked') ? '--clicked' : null,
			)}
		>
			<div className="ts-view__header">
				<div>
					<div
						className={classNames(
							panelActions.getView() !== '2d' ? '--active' : null,
						)}
					>
						<button
							onClick={() => container.app.actions.switchView('2d')}
							className={classNames(
								'ts-btn --dark',
								panelActions.getView() === '2d' ? 'active' : null,
							)}
						>
							<span>widok 2D</span>
						</button>
					</div>
				</div>

				<div>
					<div
						className={classNames(
							panelActions.getView() !== '3d' ? '--active' : null,
						)}
					>
						<button
							onClick={() => container.app.actions.switchView('3d')}
							className={classNames(
								'ts-btn --dark',
								panelActions.getView() === '3d' ? 'active' : null,
							)}
						>
							<span>widok 3D</span>
						</button>
					</div>
				</div>
			</div>

			<div className="ts-view__col">
				<div className="section-scrolled">
					{(actions.get('mode') === 'modify') ? content() : (
						<div className="ts-view__items --b">
							<p>
								Jesteś obecnie w trybie podglądu, jeśli chcesz edytować projekt kliknij w link otrzymany w&nbsp;wiadomości e-mail.
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};


Panel.defaultProps = {
	type: null,
	object: null,
};

Panel.propTypes = {
	type: PropTypes.oneOf(['settings']),
	object: PropTypes.object,
};


const mapStateToProps = (state) => ({
	type: state.panel.type,
	state: JSON.stringify(state.panel.state),
	object: state.panel.object,

	bar: state.app.bar,
	barClicked: state.app.barClicked,

	view: state.panel.view,
	mode: state.panel.mode,
});

export default connect(mapStateToProps, null)(Panel);