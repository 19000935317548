import React from 'react';

import reducer from './redux/reducer';

import Home from './views/Home';


export default {
	reducers: {
		app: reducer,
	},
	views: [
		{
			route: { path: '', component: <Home />, auth: false },
		},
	],
};