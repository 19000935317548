import Helper from 'classes/Tools/Helper';
import Maths from 'classes/Tools/Maths';
import Objects from 'classes/Tools/Objects';

import ManufactureMesh from './ManufactureMesh';


class Manufacture extends ManufactureMesh {
	constructor(instance, source) {
		super();

		this.instance = instance;
		this.three = instance.three;

		this.source = source;

		// settings
		this.status = this.setStatus();
		this.position = this.calcPosition();

		this.material = this.setMaterial();
		this.data = this.calcData();

		// objects
		this.objects = {
			fixed: null,
			handle1: null,
			handle2: null,
			hinge1: null,
			hinge2: null,
		};

		this.lock = {
			handle1: null,
			handle2: null,
		};

		this.do();
	}

	setStatus = () => Objects.in(this.source.kind, ['gateSliding', 'gateSwing', 'wicket'])

	calcPosition = () => ({
		x: this.source.data.center.x,
		y: this.source.data.center.y,
		z: this.source.data.center.z,
	})

	setMaterial = () => Objects.merge(this.source.system.profile.materials[this.instance.settings.profile.material], this.source.system[this.source.kind])

	calcData = () => {
		let direction;

		let rotation;
		let width;
		let height;
		let space;
		let panels;
		let handle1;
		let handle2;
		let hinge1;
		let hinge2;

		let panelsHeight;
		let panelsCount;
		let panelsSpace;

		let diff;
		let pos;

		if (this.source.config.direction === 'left') {
			direction = 1;
		}

		if (this.source.config.direction === 'right') {
			direction = -1;
		}

		// rotation
		rotation = this.source.data.rotation;

		// width
		width = this.source.config.width;

		// height
		height = this.instance.settings.height;

		switch (this.source.kind) {
			case 'gateSliding':
				// space
				space = this.source.system.settings.space.bottom.gateSliding;

				// panels
				panelsHeight = height - Helper.aN(this.material.fraud?.gateSliding?.top) - Helper.aN(this.material.fraud?.gateSliding?.bottom) - Helper.aN(this.material.frame?.height) * 2 - Helper.aN(this.material.frame?.bottom?.height);
				panelsCount = Math.floor(0.001 + (panelsHeight + this.material.panel.space[this.source.kind]) / (this.material.panel.height + this.material.panel.space[this.source.kind]));
				panelsSpace = (panelsHeight - (panelsCount * this.material.panel.height + (panelsCount - 1) * this.material.panel.space[this.source.kind])) / 2;

				panels = {
					count: panelsCount,
					space: panelsSpace,
				};
				break;

			case 'gateSwing':
				// space
				space = this.source.system.settings.space.bottom.gateSwing;

				// panels
				panelsHeight = height - Helper.aN(this.material.fraud?.gateSwing?.top) - Helper.aN(this.material.fraud?.gateSwing?.bottom) - Helper.aN(this.material.frame?.height) * 2;
				panelsCount = Math.floor(0.001 + (panelsHeight + this.material.panel.space[this.source.kind]) / (this.material.panel.height + this.material.panel.space[this.source.kind]));
				panelsSpace = (panelsHeight - (panelsCount * this.material.panel.height + (panelsCount - 1) * this.material.panel.space[this.source.kind])) / 2;

				panels = {
					count: panelsCount,
					space: panelsSpace,
				};

				// hinge1
				diff = Maths.rotatePoint({
					x: -this.source.data.widthInner / 2,
					y: 0,
					z: 0,
				}, rotation);

				hinge1 = {
					rotation: 0,
					position: {
						x: diff.x,
						y: space,
						z: diff.z,
					},
				};

				// hinge2
				diff = Maths.rotatePoint({
					x: this.source.data.widthInner / 2,
					y: 0,
					z: 0,
				}, rotation);

				hinge2 = {
					rotation: -Math.PI,
					position: {
						x: diff.x,
						y: space,
						z: diff.z,
					},
				};
				break;

			case 'wicket':
				// space
				space = this.source.system.settings.space.bottom.wicket;

				// panels
				panelsHeight = height - Helper.aN(this.material.fraud?.wicket?.top) - Helper.aN(this.material.fraud?.wicket?.bottom) - Helper.aN(this.material.frame?.height) * 2;
				panelsCount = Math.floor(0.001 + (panelsHeight + this.material.panel.space[this.source.kind]) / (this.material.panel.height + this.material.panel.space[this.source.kind]));
				panelsSpace = (panelsHeight - (panelsCount * this.material.panel.height + (panelsCount - 1) * this.material.panel.space[this.source.kind])) / 2;

				panels = {
					count: panelsCount,
					space: panelsSpace,
				};

				// handle1
				pos = (-this.source.data.widthInner / 2 + this.material.frame.depth / 2 + 0.005) * direction;

				diff = Maths.rotatePoint({
					x: pos,
					y: 0,
					z: -this.material.frame.depth / 2,
				}, rotation);

				handle1 = {
					rotation: {
						model: 0,
						hand: (direction === 1) ? Math.PI : 0,
					},
					position: {
						x: diff.x,
						y: space + this.material.handle.space,
						z: diff.z,
					},
				};

				// handle2
				diff = Maths.rotatePoint({
					x: pos,
					y: 0,
					z: this.material.frame.depth / 2,
				}, rotation);

				handle2 = {
					rotation: {
						model: Math.PI,
						hand: (direction === 1) ? 0 : Math.PI,
					},
					position: {
						x: diff.x,
						y: space + this.material.handle.space,
						z: diff.z,
					},
				};

				// hinge1
				diff = Maths.rotatePoint({
					x: (this.source.data.widthInner / 2) * direction,
					y: 0,
					z: 0,
				}, rotation);

				hinge1 = {
					rotation: (direction === 1) ? Math.PI : 0,
					position: {
						x: diff.x,
						y: space,
						z: diff.z,
					},
				};
				break;

			default:
		}

		return {
			rotation,
			width,
			height,
			space,
			panels,
			handle1,
			handle2,
			hinge1,
			hinge2,
		};
	}

	do = () => {
		if (!this.instance.isMoving) {
			this.doFixed();
			this.doHandle1();
			this.doHandle2();
			this.doHinge1();
			this.doHinge2();
		}
	}

	update = () => {
		this.status = this.setStatus();
		this.position = this.calcPosition();

		this.material = this.setMaterial();
		this.data = this.calcData();

		this.do();
	}

	remove = () => {
		this.three.scenes['3d'].remove(this.objects.fixed);
		this.three.scenes['3d'].remove(this.objects.handle1);
		this.three.scenes['3d'].remove(this.objects.handle2);
		this.three.scenes['3d'].remove(this.objects.hinge1);
		this.three.scenes['3d'].remove(this.objects.hinge2);
	}
}


export default Manufacture;