import React from 'react';

import * as actions from 'modules/App/redux/actions';

import Http from 'classes/Http';
import Objects from 'classes/Tools/Objects';
import GTM from 'classes/GTM';

import ValuationContent from '../../components/Valuation';


class Valuation {
	constructor(instance) {
		this.instance = instance;
		this.three = instance.three;
	}

	execute = () => {
		actions.set('loading', 'Trwa obliczanie...');

		let data = {
			system: this.instance.system,
			config: {
				system: this.instance.getSystem(),
			},
			settings: this.instance.settings,
			episodes: [],
		};

		Objects.entries(this.instance.episodes).forEach(([id, p]) => {
			data.episodes[id] = {
				kind: p.kind,
				config: p.config,
				data: {
					counts: p.data.counts,
					wall: {
						width: p.data.wall.width,
						prelast: p.data.wall.prelast,
						last: p.data.wall.last,
					},
				},
			};
		});

		const project = {
			data: JSON.stringify(data),
		};

		Http.post('systems/valuation', {
			data: { project },
			success: (response) => {
				if (response.status && response.data) {
					actions.popup(<ValuationContent data={response.data} />);
				}

				GTM.send(null, 'Summary');

				actions.set('loading', false);
			},
		});
	}
}


export default Valuation;