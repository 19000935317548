import axios from 'axios';
import qs from 'qs';

import container from 'redux/container';


class Http {
	constructor(method, url, options) {
		const defaults = {
			external: false,
			params: {},
			data: {},
			success: () => {},
			cancelled: () => {
				// console.log('Cancelled!');
			},
			error: (error) => {
				// eslint-disable-next-line no-console
				console.log(error);
			},
			cancel: null,
		};

		const args = { ...defaults, ...options };

		const uri = (!args.external) ? container.config.api?.url + url : url;
		const params = (args.params) ? qs.stringify(args.params) : '';

		if (params) {
			url = (uri.indexOf('?') === -1) ? `${uri}?${params}` : `${uri}&${params}`;
		} else {
			url = uri;
		}

		return axios({
			method,
			url,
			data: (method === 'post' && args.data) ? qs.stringify(args.data) : null,
			headers: (method === 'post') ? { 'Content-Type': 'application/x-www-form-urlencoded' } : null,
			cancelToken: (args.cancel) ? new axios.CancelToken(args.cancel) : null,
			withCredentials: (!args.external) ? container.config.api?.withCredentials : false,
		}).then((response) => {
			switch (response.status) {
				case 200:
					args.success(response.data);
					break;

				case 401:
					window.location.replace('/');
					break;

				default:
			}
		}).catch((error) => {
			if (axios.isCancel(error)) {
				args.cancelled(error);
			} else {
				args.error(error);
			}
		});
	}

	static get(url, args) {
		return new Http('get', url, args);
	}

	static post(url, args) {
		return new Http('post', url, args);
	}
}


export default Http;