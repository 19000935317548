import React from 'react';

import * as actions from 'modules/App/redux/actions';

import Http from 'classes/Http';
import Objects from 'classes/Tools/Objects';

import EmailModal from '../../components/EmailModal';


class Storage {
	constructor(instance) {
		this.instance = instance;
	}

	data = () => {
		let data = {
			system: this.instance.system,
			settings: this.instance.settings,
			house: {
				position: this.instance.objects.house.position,
				rotation: this.instance.objects.house.rotation,
				scale: this.instance.objects.house.scale,
				status: this.instance.objects.house.status,
			},
			episodes: [],
		};

		Objects.entries(this.instance.episodes).forEach(([id, p]) => {
			// episodes
			data.episodes[id] = {
				id,
				kind: p.kind,
				hook: p.hook,
				position: p.position,
				config: p.config,
			};
		});

		return JSON.stringify(data);
	}

	load = (project) => {
		try {
			const data = project.data;

			if (data && data.settings) {
				this.instance.isLoading = true;

				// system
				this.instance.system = data.system === 'standard' ? 'modern' : data.system;

				// settings
				this.instance.settings = data.settings;
				this.instance.settings.ground = data.settings.ground !== undefined ? data.settings.ground : 'grass';

				// textures
				this.instance.updateTextures();

				// house
				this.instance.objects.house.position = data.house.position;
				this.instance.objects.house.rotation = data.house.rotation;
				this.instance.objects.house.scale = data.house.scale;
				this.instance.objects.house.status = data.house.status;

				// episodes
				Objects.values(data.episodes).forEach((p) => {
					if (p) {
						this.instance.addEpisode(p.id, p.kind, p.hook, p.position, p.config);
					}
				});

				this.instance.isLoading = false;
			}
		} catch (Exception) {
			//
		}

		this.instance.unsetModified();
	}

	save = (email = false) => {
		if (!(this.instance.email || email)) {
			actions.modal(
				<EmailModal action={(value) => { this.save(value); }} />,
				'Zapisz swój projekt ogrodzenia',
			);
		} else {
			actions.set('loading', 'Trwa zapisywanie...');

			const project = {
				email,
				data: this.data(),
			};

			Http.post(`projects/edit/${this.instance.id}/${this.instance.hash}`, {
				data: { project },
				success: (response) => {
					if (response.status) {
						this.instance.unsetModified();
					}

					if (response.email) {
						this.instance.email = response.email;
					}

					actions.set('loading', false);
				},
			});
		}
	}
}


export default Storage;