import types from './types';


const init = {
	loading: false,
	popup: null,
	modal: null,
	bar: true,
	barClicked: false,
};

const reducer = (state = init, action) => {
	switch (action.type) {
		case types.SET:
			return {
				...state,
				[action.name]: action.value,
			};

		case types.BAR:
			return {
				...state,
				bar: action.status,
				barClicked: true,
			};

		default:
			return state;
	}
};


export default reducer;