import React from 'react';

import container from 'redux/container';


const ButtonSwitchGround = () => {
	return (
		<div className="ts-tooltip-init">
			<button onClick={() => { container.app.actions.switchGround(); }} type="button" className="ts-btni --sq">
				<span className="icon-background" />
			</button>

			<div className="ts-tooltip --rb --xs">
				<p>Przełącz tło</p>
			</div>
		</div>
	);
};


export default ButtonSwitchGround;