import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { usePlacesWidget } from 'react-google-autocomplete';

import container from 'redux/container';

import Http from 'classes/Http';


const SearchForm = (props) => {
	let form;

	const { ref } = usePlacesWidget({
		apiKey: container.config.google,
		options: {
			componentRestrictions: { country: 'pl' },
			types: ['geocode'],
			fields: ['formatted_address', 'geometry'],
		},
		onPlaceSelected: (place) => {
			if (place.geometry) {
				Http.post('projects/coords', {
					data: {
						lat: place.geometry.location.lat(),
						lon: place.geometry.location.lng(),
					},
					success: (response) => {
						form.setFieldValue('address', place.formatted_address);
						form.setFieldValue('x', response.x);
						form.setFieldValue('y', response.y);
					},
					error: () => {
						if (container.config.env === 'dev') {
							form.setFieldValue('address', place.formatted_address);
							form.setFieldValue('x', 601933.37);
							form.setFieldValue('y', 205447.24);
						}
					},
				});
			}
		},
	});

	const submit = (data) => {
		if (data.x && data.y) {
			props.submit(data);
		}
	};

	return (
		<Formik
			initialValues={{
				address: props.address,
			}}
			validationSchema={Yup.object().shape({
				address: Yup.string().required('Podaj adres, aby kontynuować'),
			})}
			onSubmit={submit}
			render={() => (
				<Form>
					{(props.type === 'vertical') && (
						<div>
							<div className="relative">
								<label>
									<Field type="text" name="address" className="ts-input" placeholder="Lokalizacja" aria-label="Lokalizacja" autoComplete="off" innerRef={ref} />
									<span className="ts-input__h">Lokalizacja</span>
								</label>
							</div>

							<button type="submit" className="ts-btn --dark lg:w-full">
								<span>{props.button}</span>
							</button>
						</div>
					)}

					{(props.type === 'horizontal') && (
						<div className="flex items-center h-full">
							<div className="flex-grow">
								<div className="flex ts-header__inputs">
									<div className="flex-none">
										<span className="ts-btni">
											<span className="icon-marker" />
										</span>
									</div>

									<label className="flex-grow">
										<Field type="text" name="address" className="ts-input" placeholder="Lokalizacja" aria-label="Lokalizacja" autoComplete="off" innerRef={ref} />
										<div className="ts-input__h">Lokalizacja</div>
									</label>
								</div>
							</div>

							<div className="flex-none">
								<button type="submit" className="ts-btnt --dark">
									<span>{props.button}</span>
								</button>
							</div>
						</div>
					)}
				</Form>
			)}
			innerRef={(element) => { if (element) form = element; }}
		/>
	);
};


SearchForm.defaultProps = {
	address: null,
	button: 'Szukaj',
};

SearchForm.propTypes = {
	type: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
	address: PropTypes.string,
	button: PropTypes.string,
	submit: PropTypes.func.isRequired,
};


export default SearchForm;