import React from 'react';


class Workspace extends React.Component {
	render() {
		return (
			<div
				style={{ width: '100%', height: '100%', position: 'absolute' }}
				ref={(element) => {
					this.canvas = element;
				}}
			/>
		);
	}
}


export default Workspace;