import React from 'react';

import container from 'redux/container';
import * as actions from 'modules/App/redux/actions';

import Button from './Button';


const Menu = () => {
	return (
		<div className="flex items-center h-full">
			{(!actions.get('popup')) && (
				<Button action={() => container.app.extensions.screenshot.execute()} type="camera" description="Zrób zdjęcie" />
			)}

			{(actions.get('mode') === 'modify' && !actions.get('popup')) && (
				<Button action={() => container.app.extensions.storage.save()} type="save" description="Zapisz projekt" />
			)}

			<Button action={() => { window.location.href = '/'; }} type="refresh" description="Nowy projekt" />

			{(actions.get('mode') === 'modify' && !actions.get('popup')) && (
				<div className="pl-8">
					<button onClick={() => container.app.actions.clear()} type="button" className="ts-btnt --dark-b --clear">
						<span>Wyczyść</span>
					</button>
				</div>
			)}
		</div>
	);
};


export default Menu;