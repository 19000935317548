class ThreeBuffer {
	constructor(init, success) {
		this.init = init;
		this.success = success;

		this.buffer = 0;
	}

	add = (instances = 1) => {
		this.buffer += instances;

		if (this.init) {
			this.init();
		}

		document.documentElement.classList.add('waiting');
	}

	remove = () => {
		this.buffer--;

		if (this.buffer <= 0) {
			this.reload();

			document.documentElement.classList.remove('waiting');
		}
	}

	reload = () => {
		if (this.success) {
			this.success();
		}
	}
}


export default ThreeBuffer;