import container from 'redux/container';


class GTM {
	static send = (queryformId, queryformStatus, data = {}, once = false, env = container.config.env) => {
		/* data = {
			gtm_value: '',
			gtm_customer_phone: '',
			gtm_customer_mail: '',
			...
		}; */

		if (once) {
			window.changeStatus = window.changeStatus || [];

			if (!window.changeStatus[queryformStatus]) {
				window.changeStatus[queryformStatus] = true;
			} else {
				return;
			}
		}

		if (queryformStatus === 'initForm') {
			window.changeStatus = window.changeStatus || [];

			if (!window.changeStatus[queryformId]) {
				window.changeStatus[queryformId] = true;
			} else {
				return;
			}
		}

		if (queryformStatus === 'sendForm') {
			GTM.clear(queryformId);
		}

		const content = {
			...{
				event: 'gtm_queryform',
				gtm_queryform_id: queryformId || 'Configurator',
				gtm_queryform_status: queryformStatus,
			},
			...data,
		};

		if (env === 'dev') {
			// eslint-disable-next-line no-console
			console.log(content);
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(content);
		}
	}

	static clear = (queryformId) => {
		window.changeStatus = window.changeStatus || [];

		if (window.changeStatus[queryformId]) {
			window.changeStatus[queryformId] = false;
		}
	}
}


export default GTM;