import React from 'react';
import PropTypes from 'prop-types';

import Helper from 'classes/Tools/Helper';


const FieldInput = (props) => {
	const validate = (value) => {
		if (props.min && value < props.min) {
			value = props.min;
		}

		if (props.max && value > props.max) {
			value = props.max;
		}

		return value;
	};

	const onChange = (event) => {
		let { value } = event.target;

		props.action(value);
	};

	const onBlur = (event) => {
		let { value } = event.target;

		switch (props.type) {
			case 'int':
				value = parseInt(value, 10);
				if (Number.isNaN(value)) value = 0;

				value = validate(value);
				value = Helper.numberFormat(value, 0);
				break;

			case 'float':
				value = parseFloat(value.replace(',', '.'));
				if (Number.isNaN(value)) value = 0;

				value = validate(value);
				value = Helper.numberFormat(value, 2, '.');
				break;

			default:
		}

		props.action(value);
	};

	return (
		<>
			{props.title && (
				<div className="ts-content text-sm">
					<p>{props.title}</p>
				</div>
			)}

			<label className="ts-label">
				<input className="ts-input --sm --sh" value={props.value} onChange={onChange} onBlur={onBlur} />
			</label>
		</>
	);
};


FieldInput.defaultProps = {
	action: null,
	title: null,
	// description: null,
	value: null,
	type: 'text',
	min: null,
	max: null,
};

FieldInput.propTypes = {
	action: PropTypes.func,
	title: PropTypes.string,
	// description: PropTypes.string,
	value: PropTypes.string,
	type: PropTypes.oneOf(['text', 'int', 'float']),
	min: PropTypes.number,
	max: PropTypes.number,
};


export default FieldInput;