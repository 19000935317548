import React from 'react';


const ButtonFullScreen = () => {
	const toggle = () => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};

	return (
		<div className="ts-tooltip-init">
			<button onClick={() => { toggle(); }} type="button" className="ts-btni --sq">
				<span className="icon-screen-maximize" />
			</button>

			<div className="ts-tooltip --rb --xs">
				<p>Pełny ekran</p>
			</div>
		</div>
	);
};


export default ButtonFullScreen;