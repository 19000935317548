import * as THREE from 'three';

import Objects from 'classes/Tools/Objects';

import GateSlidingGeometry from '../../geometry/Manufacture/GateSlidingGeometry';
import GateSwingGeometry from '../../geometry/Manufacture/GateSwingGeometry';
import WicketGeometry from '../../geometry/Manufacture/WicketGeometry';


class Manufacture {
	/* --- MESH ----------------------------------------------- */

	doFixed = () => {
		if (this.status) {
			let object;

			switch (this.source.kind) {
				case 'gateSliding':
					object = this.doFixedGateSliding();
					break;

				case 'gateSwing':
					object = this.doFixedGateSwing();
					break;

				case 'wicket':
					object = this.doFixedWicket();
					break;

				default:
			}

			if (object) {
				if (!this.objects.fixed) {
					this.objects.fixed = new THREE.Mesh(object.geometry, object.material);
					this.objects.fixed.castShadow = true;
					this.objects.fixed.receiveShadow = false;
					this.three.scenes['3d'].add(this.objects.fixed);
				} else {
					this.objects.fixed.geometry = object.geometry;
					this.objects.fixed.material = object.material;
				}

				this.objects.fixed.position.set(this.position.x, this.position.y + this.data.space, this.position.z);
				this.objects.fixed.rotation.y = this.data.rotation;
				this.objects.fixed.visible = this.status;
			}
		} else if (this.objects.fixed) {
			this.objects.fixed.visible = false;
		}
	}

	doFixedGateSliding = () => {
		let material = this.getMaterial();

		let geometry = new GateSlidingGeometry(
			this.data.width,
			this.data.height,
			{
				counts: {
					panels: this.data.panels.count,
				},
				inside: this.source.poleMaterial.depth / 2 + 0.08,
				frame: this.material.frame,
				slat: this.material.slat,
				panel: this.material.panel,
				fraud: this.material.fraud,
				space: this.data.panels.space,
				mix: this.source.system.profile.mixes[this.instance.settings.profile.mix],
			},
			this.source.config.direction,
			this.source.config.side,
		);

		return { material, geometry };
	}

	doFixedGateSwing = () => {
		let material = this.getMaterial();

		let geometry = new GateSwingGeometry(
			this.data.width,
			this.data.height,
			{
				counts: {
					panels: this.data.panels.count,
				},
				between: this.material.between,
				hinge: this.material.hinge,
				frame: this.material.frame,
				slat: this.material.slat,
				panel: this.material.panel,
				fraud: this.material.fraud,
				space: this.data.panels.space,
				mix: this.source.system.profile.mixes[this.instance.settings.profile.mix],
			},
		);

		return { material, geometry };
	}

	doFixedWicket = () => {
		let material = this.getMaterial();

		let geometry = new WicketGeometry(
			this.data.width,
			this.data.height,
			{
				counts: {
					panels: this.data.panels.count,
				},
				hinge: this.material.hinge,
				frame: this.material.frame,
				slat: this.material.slat,
				panel: this.material.panel,
				fraud: this.material.fraud,
				space: this.data.panels.space,
				mix: this.source.system.profile.mixes[this.instance.settings.profile.mix],
			},
			this.source.config.direction,
		);

		return { material, geometry };
	}

	doHandle1 = () => {
		this.doHandle('handle1');
	}

	doHandle2 = () => {
		this.doHandle('handle2');
	}

	doHandle = (indicator) => {
		if (this.status && this.data[indicator]) {
			const model = 'handle';

			if (this.lock[indicator] !== model) {
				this.lock[indicator] = model;

				this.three.gltf.load(`/assets/models/${model}.gltf`, (gltf) => {
					if (this.objects[indicator]) {
						this.three.scenes['3d'].remove(this.objects[indicator]);
					}

					this.objects[indicator] = gltf.scene;

					this.objects[indicator].traverse((o) => {
						if (o instanceof THREE.Mesh) {
							o.castShadow = false;
							o.receiveShadow = false;

							switch (o.name) {
								case 'Back':
								case 'Circle':
								case 'Hand':
									o.material = this.instance.materials.chrome;
									break;

								case 'Circle Old':
									/* o.material.metalness = 0.4;
									o.material.roughness = 1; */
									break;

								default:
									o.material.metalness = 0;
									o.material.roughness = 1;
									break;
							}
						}
					});

					this.three.scenes['3d'].add(this.objects[indicator]);

					this.doHandle(indicator);
				});
			} else if (this.objects[indicator]) {
				this.objects[indicator].traverse((o) => {
					if (o instanceof THREE.Mesh) {
						switch (o.name) {
							case 'Hand':
								o.rotation.z = this.data[indicator].rotation.hand;
								break;

							default:
						}
					}
				});

				this.objects[indicator].position.set(this.position.x + this.data[indicator].position.x, this.position.y + this.data[indicator].position.y, this.position.z + this.data[indicator].position.z);
				this.objects[indicator].rotation.y = this.data.rotation + this.data[indicator].rotation.model;
				this.objects[indicator].scale.set(0.01, 0.01, 0.01);
				this.objects[indicator].visible = this.status;
			}
		} else if (this.objects[indicator]) {
			this.objects[indicator].visible = false;
		}
	}

	doHinge1 = () => {
		this.doHinge('hinge1');
	}

	doHinge2 = () => {
		this.doHinge('hinge2');
	}

	doHinge = (indicator) => {
		if (this.status && this.data[indicator]) {
			const model = 'hinge';
			const scale = 0.01;

			if (this.lock[indicator] !== model) {
				this.lock[indicator] = model;

				this.three.gltf.load(`/assets/models/${model}.gltf`, (gltf) => {
					if (this.objects[indicator]) {
						this.three.scenes['3d'].remove(this.objects[indicator]);
					}

					this.objects[indicator] = gltf.scene;

					this.objects[indicator].traverse((o) => {
						if (o instanceof THREE.Mesh) {
							o.castShadow = false;
							o.receiveShadow = false;

							if (!Objects.in(o.name, ['X1', 'X2'])) {
								o.material = this.instance.materials.chrome;
							}

							const weight = 0.005;
							const pos = [this.material.hinge.space + this.material.hinge.height / 2, this.data.height - this.material.hinge.space - this.material.hinge.height / 2];

							if (Objects.in(o.name, ['B1', 'X1'])) {
								if (o.name === 'B1') {
									o.position.x = (this.material.hinge.width - 0.039) / scale;
								}

								o.position.y = pos[0] / scale;
								o.scale.y = (this.material.hinge.height / 0.026);
							}

							if (Objects.in(o.name, ['B2', 'X2'])) {
								if (o.name === 'B2') {
									o.position.x = (this.material.hinge.width - 0.039) / scale;
								}

								o.position.y = pos[1] / scale;
								o.scale.y = (this.material.hinge.height / 0.026);
							}

							if (o.name === 'H1T') {
								o.position.y = (pos[0] - this.material.hinge.height / 2 + weight / 2) / scale;
								o.scale.x = (this.material.hinge.width / 0.039);
							}

							if (o.name === 'H1B') {
								o.position.y = (pos[0] + this.material.hinge.height / 2 - weight / 2) / scale;
								o.scale.x = (this.material.hinge.width / 0.039);
							}

							if (o.name === 'H2T') {
								o.position.y = (pos[1] - this.material.hinge.height / 2 + weight / 2) / scale;
								o.scale.x = (this.material.hinge.width / 0.039);
							}

							if (o.name === 'H2B') {
								o.position.y = (pos[1] + this.material.hinge.height / 2 - weight / 2) / scale;
								o.scale.x = (this.material.hinge.width / 0.039);
							}

							o.scale.z = (this.material.hinge.depth / 0.025);
						}
					});

					this.three.scenes['3d'].add(this.objects[indicator]);

					this.doHinge(indicator);
				});
			} else if (this.objects[indicator]) {
				this.objects[indicator].position.set(this.position.x + this.data[indicator].position.x, this.position.y + this.data[indicator].position.y, this.position.z + this.data[indicator].position.z);
				this.objects[indicator].rotation.y = this.data.rotation + this.data[indicator].rotation;
				this.objects[indicator].scale.set(scale, scale, scale);
				this.objects[indicator].visible = this.status;
			}
		} else if (this.objects[indicator]) {
			this.objects[indicator].visible = false;
		}
	}


	/* --- MATERIALS ------------------------------------------ */

	getMaterial = () => {
		return new THREE.MeshFaceMaterial([
			// 0
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,
			this.instance.systemMaterials.profiles.mixes,

			// 1
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,
			this.instance.systemMaterials.profiles.panels,

			// 2
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,
			this.instance.systemMaterials.profiles.slats,

			// 3
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
			this.instance.systemMaterials.profiles.frames,
		]);
	}
}


export default Manufacture;