import * as THREE from 'three';
import DragControls from 'three-dragcontrols';

import container from 'redux/container';
import * as panelActions from '../redux/panel/actions';

import Three from 'classes/Three/Three';
import ThreeEnvironment from 'classes/Three/ThreeEnvironment';
import ThreeView from 'classes/Three/ThreeView';

import Screenshot from './extensions/Screenshot';
import Storage from './extensions/Storage';
import Valuation from './extensions/Valuation';


class Core {
	constructor(args) {
		panelActions.reset();

		this.canvas = args.canvas;
		this.config = args.config;
		this.debug = args.debug;
		this.id = args.id;
		this.hash = args.hash;
		this.mode = args.mode;
		this.path = args.path;
		this.email = args.email;
		this.project = args.project;

		this.details = this.loadDetails();
		this.colors = this.loadColors();

		this.three = new Three(this, this.canvas[0]);
		this.three.view = new ThreeView(this, this.three);
		this.three.environment = new ThreeEnvironment(this, this.three);

		this.extensions = {
			storage: new Storage(this),
			screenshot: new Screenshot(this),
			valuation: new Valuation(this),
		};

		this.font = {
			weight: 'bold',
			size: 0.5,
			family: '"Arial", "sans-serif"',
			color: '#000000',
		};

		this.textures = [];
		this.materials = [];

		this.highlight = null;

		this.dragging = 0;
		this.isLoading = false;
		this.isMoving = false;
		this.isModified = false;
	}

	loadDetails = () => ({
		framelimit: 'on',
		antialiassing: 'on',
		shadowsPrecision: 50,
	})

	loadColors = () => ({
		background: 0xffffff,
		line: 0x000000,
		house: 0x000000,
		sketch: {
			fence: 0xe0e0e0,
			gateSliding: 0x606060,
			gateSwing: 0x606060,
			wicket: 0x606060,
		},
	})

	loadScenes = () => {
		// 2d
		this.three.view.add('2d', {
			light: 1,
			position: [0, 60, 0],
			controlsMinDistance: 15,
			controlsMaxDistance: 100,
			controlsMaxDistanceX: 40,
			controlsMaxDistanceY: 40,
			controlsMaxDistanceZ: 40,
			controlsRotate: true,
			controlsMaxPolarAngle: 0,
			controlsMouseButtons: { LEFT: null, MIDDLE: THREE.MOUSE.ROTATE, RIGHT: THREE.MOUSE.PAN },
			background: this.colors.background,
		});

		this.three.environment.addGround('2d', 'map', { source: `${container.config.api?.url}uploads/TsProjects/${this.path}`, size: 33.7 * 5, repeatX: 1, repeatY: 1, opacity: 0.95, transparent: true });

		// 3d
		this.three.view.add('3d', {
			light: 0.8,
			position: [-2.5, 7.5, 35],
			controlsMaxDistance: 50,
			controlsMaxDistanceX: 50,
			controlsMaxDistanceY: 50,
			controlsMaxDistanceZ: 50,
			background: 0xffffff,
		});

		this.three.environment.addFog('3d', { color: 0x72a5e8, distance: 650 });
		// this.three.environment.addGrid('3d', 'grid-1', { frequency: 1, maincolor: 0xffffff, color: 0x909090 });
		// this.three.environment.addGrid('3d', 'grid-10', { frequency: 10, maincolor: 0xffffff, color: 0xc0c0c0 });
		// this.three.environment.addLogo('3d', 'logos', { source: '/assets/img/environment/logos.png', repeatX: 50, repeatY: 150, opacity: 0.4, transparent: true });
		this.three.environment.addSky('3d', 'sky', { source: '/assets/img/environment/clouds.png', repeatX: 2, repeatY: 4.5 });
		this.three.environment.addGround('3d', 'grass', { source: '/assets/img/environment/grass.jpg', repeatX: 50, repeatY: 50, visible: false });
		this.three.environment.addGround('3d', 'satelite', { source: `${container.config.api?.url}uploads/TsProjects/${this.path}`, size: 33.7 * 5, repeatX: 1, repeatY: 1, intensity: 0.75, visible: false });
		this.three.environment.addLight('3d', 'sun1', { intensity: 0.65, position: [0, 50, 100], shadow: true });
		this.three.environment.addLight('3d', 'sun2', { intensity: 0.50, position: [0, 50, -100], shadow: false });

		// set default
		this.setView('2d', true);
	}

	loadTextures = () => {
		this.envMap = this.createEnvMap('/assets/img/map/', ['land', 'land', 'land', 'land', 'land', 'land'], 'jpg');
		this.envMapVirtual = this.createEnvMap('/assets/img/map/', ['virtual', 'virtual', 'virtual', 'virtual', 'virtual', 'virtual'], 'jpg');
	}

	loadMaterials = () => {
		this.createMaterial('point', 'Basic', { source: '/assets/img/geometry/point.png', transparent: true }, true);
		this.createMaterial('pointactive', 'Basic', { source: '/assets/img/geometry/pointactive.png', transparent: true }, true);
		this.createMaterial('rotation', 'Basic', { source: '/assets/img/geometry/rotation.png', transparent: true }, true);
		this.createMaterial('rotationactive', 'Basic', { source: '/assets/img/geometry/rotation.png', transparent: true }, true);
		this.createMaterial('scale', 'Basic', { source: '/assets/img/geometry/scale.png', transparent: true }, true);
		this.createMaterial('scaleactive', 'Basic', { source: '/assets/img/geometry/scale.png', transparent: true }, true);

		this.createMaterial('chrome', 'Standard', {
			color: 0xffffff,
			metalness: 0.75,
			roughness: 0.5,
			envMap: this.envMap,
		});

		this.createMaterial('black', 'Standard', {
			color: 0x404040,
			metalness: 0.75,
			roughness: 0.1,
			side: THREE.DoubleSide,
			envMap: this.envMap,
		});

		this.createMaterial('glass', 'Standard', {
			color: 0xffffff,
			metalness: 0.75,
			roughness: 0.1,
			transparent: true,
			opacity: 0.75,
			side: THREE.DoubleSide,
			envMap: this.envMap,
			envMapIntensity: 0.5,
		});
	}

	loadEvents = () => {
		['beforeunload'].forEach((event) => window.addEventListener(event, (e) => {
			if (!this.debug && this.isModified) {
				(e || window.event).returnValue = true;

				return true;
			}

			return false;
		}));
	}


	/* --- CREATORS ------------------------------------------- */

	createTexture = (source, buffer = false) => {
		if (buffer) {
			this.buffer.add();
		}

		return this.three.loader.load(source, () => {
			if (buffer) {
				this.buffer.remove();
			}
		}, false, () => {
			if (buffer) {
				this.buffer.remove();
			}
		});
	}

	createMaterial = (id, type, options, buffer = false) => {
		const method = `Mesh${type}Material`;

		const defaults = {
			repeatX: 1,
			repeatY: 1,
			aoMapIntensity: 0.0,
			envMapIntensity: 1.0,
		};

		const args = { ...defaults, ...options };

		if (args.source) {
			if (buffer) {
				this.buffer.add();
			}

			this.three.loader.load(args.source, (image) => {
				const texture = image.clone();
				texture.needsUpdate = true;
				texture.wrapS = THREE.RepeatWrapping;
				texture.wrapT = THREE.RepeatWrapping;
				texture.repeat.set(args.repeatX, args.repeatY);
				texture.anisotropy = 8;

				this.materials[id] = new THREE[method]({ ...args, ...{ map: texture, aoMap: texture } });

				if (buffer) {
					this.buffer.remove();
				}
			}, false, () => {
				if (buffer) {
					this.buffer.remove();
				}
			});
		} else {
			this.materials[id] = new THREE[method](args);
		}
	}

	createEnvMap = (path, map, format) => {
		// square textures required
		// map: [right, left, top, bottom, front, back]

		let urls = [];

		for (let i = 0; i < 6; i++) {
			switch (typeof map) {
				case 'string':
					urls.push(`${path}${map}.${format}`);
					break;

				case 'object':
					urls.push(`${path}${map[i]}.${format}`);
					break;

				default:
			}
		}

		const envMap = new THREE.CubeTextureLoader().load(urls);
		envMap.encoding = THREE.sRGBEncoding;

		return envMap;
	}


	/* --- CLICK EVENTS --------------------------------------- */

	onClick = (o, callback) => {
		['click', 'touchend'].forEach((event) => o.on(event, () => {
			if (event === 'touchend') {
				setTimeout(() => {
					callback();
				}, 50);
			} else {
				callback();
			}
		}));
	}

	resetClick = (o) => {
		o.drag = new DragControls([o], {
			moveable: false,
		}, this.three.camera, this.three.render.domElement);

		['click', 'touchend'].forEach((event) => o.drag.addEventListener(event, () => {
			this.resetOptions();
		}));
	}

	resetOptions = () => {
		panelActions.reset();
		this.setHighlight(null);
	}


	/* --- HIGHLIGHT ------------------------------------------ */

	setHighlight = (o) => {
		if (this.highlight) {
			if (this.highlight.objects.highlight2d) {
				this.highlight.objects.highlight2d.visible = false;
			}

			if (this.highlight.objects.highlight3d) {
				this.highlight.objects.highlight3d.visible = false;
			}
		}

		if (o) {
			if (o.objects.highlight2d) {
				o.objects.highlight2d.visible = true;
			}

			if (o.objects.highlight3d) {
				o.objects.highlight3d.visible = true;
			}

			this.highlight = o;
		}
	}


	/* --- METHODS -------------------------------------------- */

	isDragging = () => {
		if (this.dragging + 100 >= Date.now()) {
			return true;
		}

		return false;
	}

	setModified = () => {
		this.isModified = true;
	}

	unsetModified = () => {
		this.isModified = false;
	}


	/* --- FUNCTIONS ------------------------------------------ */

	dpsi = (row, i = 0) => {
		let drow = 0.01;
		let di = 0.003;

		return parseFloat(row) * drow + parseFloat(i) * di;
	}
}


export default Core;