class EpisodeActions {
	constructor(instance, target) {
		this.instance = instance;
		this.target = target;
	}

	setConfig = (name, value) => {
		this.target.config[name] = value;

		this.target.reload();
	}
}


export default EpisodeActions;