import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';

import Objects from 'classes/Tools/Objects';
import Helper from 'classes/Tools/Helper';

import ContactForm from 'modules/App/components/ContactForm';


const Valuation = (props) => {
	const result = (question = false) => {
		return Objects.count(props.data.result) ? (
			<>
				<table>
					<thead>
						<tr>
							<th className="text-left lg:pl-24">Nazwa</th>
							<th>Ilość</th>
							{(props.data.PRICES) && (
								<>
									<th>Cena</th>
									<th>Suma</th>
								</>
							)}
						</tr>
					</thead>

					<tbody>
						{Objects.entries(props.data.result).map(([i, p]) => (
							<tr key={i}>
								<td className="text-tiny xl:text-org text-left lg:pl-4 xl:pl-8">
									<div className="flex items-center space-x-3 xl:space-x-6">
										{(p.icon) && (
											<span className={`icon-${p.icon}`} />
										)}

										<span className="leading-tight">
											{(p.name) && (
												<>
													{p.name}<br />
												</>
											)}

											{(p.description && p.description !== '-') && (
												<>
													<small>{p.description}</small><br />
												</>
											)}

											{(p.code) && (
												<>
													<small>#{p.code}</small><br />
												</>
											)}
										</span>
									</div>
								</td>

								<td className="ts-files__n">{p.number ? <>{Helper.numberFormat(p.quantity, 2, ',', ' ')}&nbsp;m<sup>2</sup> <br /> ({p.number}&nbsp;szt.)</> : <>{p.quantity}&nbsp;szt.</>}</td>

								{(props.data.PRICES) && (
									<>
										<td className="ts-files__n">{Helper.numberFormat(p.price.gross, 2, ',', ' ')}&nbsp;zł</td>

										<td className="ts-files__n">{Helper.numberFormat(p.price.gross * p.quantity, 2, ',', ' ')}&nbsp;zł</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</table>

				{(props.data.PRICES && question) && (
					<>
						<p><strong>{Helper.numberFormat(props.data.price.gross, 2, ',', ' ')}&nbsp;zł</strong> brutto</p>
						<p><strong>{Helper.numberFormat(props.data.price.net, 2, ',', ' ')}&nbsp;zł</strong> netto</p>
					</>
				)}
			</>
		) : false;
	};

	return (
		<div className="flex space-x-10">
			<div className="ts-fpan__cl lg:flex-none">
				<div className="ts-fpan__card">
					<div className="ts-heading --h5 mb-8">Zestawienie elementów</div>

					{(result()) || (
						<>
							<br /><br />Brak elementów.<br /><br />
						</>
					)}

					<div className="ts-fpan__sub">
						<div className="grid grid-cols-2">
							<div>
								<div className="space-y-1 inline-flex flex-col text-right">
									<div className="flex space-x-5">
										{(props.data.weight > 0) && (
											<>
												<span className="flex-none">Waga:</span>
												<strong className="flex-grow">{Helper.numberFormat(props.data.weight, 0, ',', ' ')} kg</strong>
											</>
										)}
									</div>

									<div className="flex space-x-5">
										{(props.data.length > 0) && (
											<>
												<spa className="flex-none">Długość:</spa>
												<strong className="flex-grow">{Helper.numberFormat(props.data.length, 0, ',', ' ')} m</strong>
											</>
										)}
									</div>
								</div>
							</div>

							{(props.data.PRICES) && (
								<div className="lg:text-right">
									<div className="ts-fpan__total">
										Całość:

										<div className="text-lg xl:text-xl">
											<strong>{Helper.numberFormat(props.data.price.gross, 2, ',', ' ')}&nbsp;zł</strong> <span className="text-tiny xl:text-org">brutto</span>
										</div>

										<div className="text-lg xl:text-normal">
											<strong>{Helper.numberFormat(props.data.price.net, 2, ',', ' ')}&nbsp;zł</strong> <span className="text-sm xl:text-tiny">netto</span>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="ts-fpan__cr lg:flex-grow" style={{ position: 'relative' }}>
				<div className="ts-fpan__card bg-gray-third">
					<ContactForm data={{ data: props.data, content: renderToString(result(true)) }} />
				</div>
			</div>
		</div>
	);
};


Valuation.defaultProps = {
	data: null,
};

Valuation.propTypes = {
	data: PropTypes.object,
};


export default Valuation;