import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import GTM from 'classes/GTM';

import * as actions from 'modules/App/redux/actions';


const EmailModal = (props) => {
	const change = () => {
		GTM.send('SaveProject', 'initForm');
	};

	const submit = (data) => {
		if (props.action) {
			props.action(data.email);

			GTM.send('SaveProject', 'sendForm', { gtm_customer_mail: data.email });
		}

		actions.modal(null);
	};

	return (
		<>
			<div>
				Podaj adres e-mail, aby zapisać swoje ogrodzenie. Na wskazany adres otrzymasz link, dzięki któremu w każdej chwili będziesz mógł wrócić do projektu i go dokończyć.
			</div>

			<Formik
				initialValues={{
					email: '',
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.required('To pole jest wymagane')
						.email('To pole wymaga podania prawidłowego adresu e-mail'),
				})}
				onSubmit={submit}
				render={() => (
					<Form onChange={change}>
						<div className="flex flex-col space-y-5">
							<div>
								<label>
									<Field name="email" className="ts-input --sh" placeholder="Twój adres e-mail" aria-label="Twój adres e-mail" />
									<span className="ts-input__h">Twój adres e-mail</span>
									<ErrorMessage name="email" component="div" className="error-message" />
								</label>
							</div>

							<div>
								<button
									type="submit"
									className="ts-btn --primary --rounded w-full --sh --lg"
								>
									<span>Zapisz projekt</span>
								</button>
							</div>
						</div>
					</Form>
				)}
			/>
		</>
	);
};


EmailModal.defaultProps = {
	action: null,
};

EmailModal.propTypes = {
	action: PropTypes.func,
};


export default EmailModal;