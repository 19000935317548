import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';
import { Interaction } from 'three-interaction';


class ThreeView {
	constructor(instance, three) {
		this.instance = instance;
		this.three = three;

		this.current = false;

		this.three.cameras = [];
		this.three.camera = null;

		this.three.scenes = [];
		this.three.scene = null;

		// point
		this.raycaster = new THREE.Raycaster();
		this.pointer = new THREE.Vector2();
	}

	add = (id, options) => {
		const defaults = {
			light: 1,
			lightColor: 0xffffff,
			position: [10, 5, 20],
			lookAt: [0, 0, 0],
			controls: true,
			controlsMinDistance: 1,
			controlsMaxDistance: 100,
			controlsRotate: true,
			controlsRotateSpeed: 0.2,
			controlsMaxPolarAngle: false,
			controlsKeys: false,
			controlsMouseButtons: { LEFT: THREE.MOUSE.ROTATE, MIDDLE: null, RIGHT: THREE.MOUSE.PAN },
			background: null,
			interaction: true,
		};

		const args = { ...defaults, ...options };

		this.three.cameras[id] = new THREE.PerspectiveCamera(45, this.three.aspect);
		this.three.cameras[id].position.set(args.position[0], args.position[1], args.position[2]);
		this.three.cameras[id].lookAt(args.lookAt[0], args.lookAt[1], args.lookAt[2]);
		this.three.cameras[id].active = false;

		this.three.scenes[id] = new THREE.Scene();

		// light
		this.three.scenes[id].AmbientLight = new THREE.AmbientLight(args.lightColor, args.light);
		this.three.scenes[id].add(this.three.scenes[id].AmbientLight);

		// controls
		if (args.controls) {
			this.three.cameras[id].controls = new OrbitControls(this.three.cameras[id], this.three.render.domElement);
			this.three.cameras[id].controls.minDistance = args.controlsMinDistance;
			this.three.cameras[id].controls.maxDistance = args.controlsMaxDistance;
			this.three.cameras[id].controls.maxPositionX = (args.controlsMaxDistanceX !== undefined) ? args.controlsMaxDistanceX : args.controlsMaxDistance;
			this.three.cameras[id].controls.maxPositionY = (args.controlsMaxDistanceY !== undefined) ? args.controlsMaxDistanceY : args.controlsMaxDistance;
			this.three.cameras[id].controls.maxPositionZ = (args.controlsMaxDistanceZ !== undefined) ? args.controlsMaxDistanceZ : args.controlsMaxDistance;
			this.three.cameras[id].controls.rotateSpeed = (args.controlsRotate) ? args.controlsRotateSpeed : 0;
			this.three.cameras[id].controls.maxPolarAngle = (args.controlsMaxPolarAngle !== false) ? args.controlsMaxPolarAngle : Math.PI / 2 - 0.075;

			this.three.cameras[id].controls.enabled = false;
			this.three.cameras[id].controls.enableKeys = args.controlsKeys;
			this.three.cameras[id].controls.mouseButtons = args.controlsMouseButtons;
		}

		// background
		if (args.background) {
			this.three.scenes[id].background = new THREE.Color(args.background);
		}

		// interaction
		if (args.interaction) {
			this.three.scenes[id].interaction = new Interaction(this.three.render, this.three.scenes[id], this.three.cameras[id]);
		}

		this.set(id);
	}

	get = () => this.current

	set = (id) => {
		// before
		if (this.three.camera) {
			this.three.camera.active = false;

			if (this.three.camera.controls) {
				this.three.camera.controls.enabled = false;
			}
		}

		// set
		this.current = id;

		this.three.camera = this.three.cameras[id];
		this.three.scene = this.three.scenes[id];

		// after
		if (this.three.camera) {
			this.three.camera.active = true;

			if (this.three.camera.controls) {
				this.three.camera.controls.enabled = true;
			}
		}

		// html
		const displayViewAll = document.querySelector('.display-view');
		if (displayViewAll) displayViewAll.style.display = 'none';

		const displayViewCurrent = document.querySelector(`.display-view-${id}`);
		if (displayViewCurrent) displayViewCurrent.style.display = 'block';
	}

	point = (e) => {
		// pointer
		const x = (e.layerX / this.three.width) * 2 - 1;
		const y = -(e.layerY / this.three.height) * 2 + 1;

		this.pointer.set(x, y);

		// raycaster
		this.raycaster.setFromCamera(this.pointer, this.three.camera);

		// intersects
		const intersects = this.raycaster.intersectObjects(this.instance.raycaster.intersects);

		if (intersects.length) {
			const intersect = intersects[0];
			const position = intersect.point;

			return position;
		}

		return false;
	}
}


export default ThreeView;