import React from 'react';


const ButtonHelp = () => {
	return (
		<div className="ts-tooltip-init">
			<a href="mailto:biuro@gardsystems.eu" className="ts-btni --sq">
				<span className="icon-info" />
			</a>

			<div className="ts-tooltip --rb --xs">
				<p>
					Napisz do nas, a pokierujemy Cię krok po kroku.<br />
					<a href="mailto:biuro@gardsystems.eu">biuro@gardsystems.eu</a>
				</p>
			</div>
		</div>
	);
};


export default ButtonHelp;