import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Objects from 'classes/Tools/Objects';


const FieldSelect = (props) => {
	const select = useRef();

	const getLabel = (value, p) => {
		if (typeof p === 'string') {
			return p;
		}

		if (typeof p === 'object' && p.label) {
			return p.label;
		}

		return value;
	};

	useEffect(() => {
		select.current.value = props.value;
	});

	return (
		<>
			{props.title && (
				<div className="ts-content text-sm">
					<p>{props.title}</p>
				</div>
			)}

			<label
				className={classNames(
					'ts-select ts-label',
					props.color ? `--${props.color}` : null,
				)}
			>
				<select className="ts-input ts-select --sm --sh" defaultValue={props.value} onChange={(e) => props.action(e.target.value, props.options[e.target.value])} ref={select}>
					{Objects.entries(props.options).map(([value, p]) => (
						<option value={value} key={value}>{getLabel(value, p)}</option>
					))}
				</select>
			</label>
		</>
	);
};


FieldSelect.defaultProps = {
	action: null,
	title: null,
	// description: null,
	options: null,
	value: null,
	color: 'white',
};

FieldSelect.propTypes = {
	action: PropTypes.func,
	title: PropTypes.string,
	// description: PropTypes.string,
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	value: PropTypes.string,
	color: PropTypes.oneOf(['white', 'gray']),
};


export default FieldSelect;