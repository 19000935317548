import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as actions from 'modules/App/redux/actions';

import LoadScripts from 'classes/Tools/LoadScripts';

import Tooltip from 'templates/MainTemplate/Tooltip';


class ILiteMap extends React.Component {
	componentDidMount = () => {
		this.LoadScripts = new LoadScripts([
			'//cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js',
			'//mapy.geoportal.gov.pl/iMapLite/js/imapLiteApi-core.js',
		], () => {
			this.LoadScripts = new LoadScripts([
				'//mapy.geoportal.gov.pl/iMapLite/js/imapLiteApi-coreEx.js',
			], () => {
				this.run();
			});
		});
	}

	run = () => {
		this.api = window.ILITEAPI;

		this.init(this.props.marker);
	}

	init = (marker) => {
		this.api.init({
			divId: this.props.id,
			width: '100%',
			height: '100%',
			gpplugins: ['clusterLayer'], // wymagane do imapLiteApi-coreEx.js
			activeGpMapId: 'gp1', // gp0 - gp5
			activeGpMaps: ['gp1'], // gp0 - gp5
			scale: 0, // 1 - 6000 or 0
			marker: (marker.x && marker.y) ? {
				x: marker.x,
				y: marker.y,
				opts: {
					show: false,
				},
			} : false,
		});
	}

	move = (marker) => {
		this.init(marker);
	}

	search = (address) => {
		if (this.api) {
			this.api.searchAddress(address);
		}
	}

	crop = () => {
		if (this.api) {
			this.api.getCenter((position) => {
				this.props.crop(position[1], position[0]);
			});
		}
	}

	render() {
		return (
			<>
				<div
					className={classNames(
						'ts-main__bg-wp',
						!actions.get('bar') ? '--full' : null,
					)}
				>
					<div className="ts-main__embed bg-black --inbar">
						<div
							id={this.props.id}
							style={{ width: '100%', height: '100%', position: 'absolute' }}
						/>

						{(this.props.crop) && (
							<>
								<div className="centered ts-viewfinder" />

								<div className="centered --b">
									<Tooltip content="Dostosuj obszar, aby działka była w całości widoczna. W następnym dokonaj obrysu." />

									<button onClick={this.crop} className="ts-btn --dark">
										<span>Kadruj!</span>
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</>
		);
	}
}


ILiteMap.defaultProps = {
	id: 'ilite-map',
	marker: null,
	crop: null,
};

ILiteMap.propTypes = {
	id: PropTypes.string,
	marker: PropTypes.object,
	crop: PropTypes.func,
};


export default ILiteMap;