class Maths {
	static round = (number, precision = 3) => {
		const exp = 10 ** precision;

		return Math.round(number * exp) / exp;
	}

	static degreesToRotation = (degrees) => degrees * (Math.PI * 2 / 360)

	static rotationToDegrees = (rotation) => rotation / (Math.PI * 2 / 360)

	static getCenter = (A, B) => ({ x: (A.x + B.x) / 2, y: (A.y + B.y) / 2, z: (A.z + B.z) / 2 })

	static getDistance = (A, B) => {
		const x = B.x - A.x;
		const y = B.y - A.y;

		return Math.sqrt(x ** 2 + y ** 2);
	}

	static getRotation = (A, B) => -Math.atan2(-(A.y - B.y), -(A.x - B.x))

	static rotatePoint = (A, rotation) => {
		const degrees = Maths.rotationToDegrees(rotation);

		const distance = Maths.getDistance({ x: 0, y: 0 }, { x: A.x, y: A.z });

		const r = Maths.rotationToDegrees(Maths.getRotation({ x: 0, y: 0 }, { x: A.x, y: A.z }) * -1) + degrees + 180;
		const sin = Math.sin(r * Math.PI / 180);
		const cos = Math.cos(r * Math.PI / 180);

		const x = distance * cos * (-1);
		const y = 0;
		const z = distance * sin * (1);

		return { x, y, z };
	}
}


export default Maths;