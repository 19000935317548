import * as THREE from 'three';


class Geometry {
	constructor(options = {}) {
		const defaults = {
			unwrap: 'fill',
			unit: {
				x: 1.20,
				y: 0.60,
			},
		};

		this.args = { ...defaults, ...options };
		this.geometry = new THREE.Geometry();

		this.D = 0.0001;
		this.vi = 0;
		this.fi = 0;
	}

	rect = (w1, w2, h1, h2, d1, d2, material = 0) => {
		// vertices
		this.geometry.vertices.push(new THREE.Vector3(w1, h1, d1));
		this.geometry.vertices.push(new THREE.Vector3(w1, h1, d2));
		this.geometry.vertices.push(new THREE.Vector3(w2, h1, d2));
		this.geometry.vertices.push(new THREE.Vector3(w2, h1, d1));

		this.geometry.vertices.push(new THREE.Vector3(w1, h2, d1));
		this.geometry.vertices.push(new THREE.Vector3(w1, h2, d2));
		this.geometry.vertices.push(new THREE.Vector3(w2, h2, d2));
		this.geometry.vertices.push(new THREE.Vector3(w2, h2, d1));

		// faces
		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 2, this.vi + 1));
		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 3, this.vi + 2));

		this.geometry.faces.push(new THREE.Face3(this.vi + 5, this.vi + 7, this.vi + 4));
		this.geometry.faces.push(new THREE.Face3(this.vi + 5, this.vi + 6, this.vi + 7));

		this.geometry.faces.push(new THREE.Face3(this.vi + 3, this.vi + 4, this.vi + 7));
		this.geometry.faces.push(new THREE.Face3(this.vi + 3, this.vi + 0, this.vi + 4));

		this.geometry.faces.push(new THREE.Face3(this.vi + 1, this.vi + 6, this.vi + 5));
		this.geometry.faces.push(new THREE.Face3(this.vi + 1, this.vi + 2, this.vi + 6));

		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 5, this.vi + 4));
		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 1, this.vi + 5));

		this.geometry.faces.push(new THREE.Face3(this.vi + 2, this.vi + 7, this.vi + 6));
		this.geometry.faces.push(new THREE.Face3(this.vi + 2, this.vi + 3, this.vi + 7));

		this.vi += 8;

		// faces material index
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 1;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 1;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 0;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 0;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 3;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 3;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 2;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 2;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 4;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 4;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 5;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 5;

		// faces vertex
		switch (this.args.unwrap) {
			case 'fill':
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));
				break;

			case 'wrap':
				this.vertices = [w1, w2, h1, h2, d1, d2];

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(1, 1), this.wrap(1, 2)), new THREE.Vector2(this.wrap(1, 3), this.wrap(1, 4)), new THREE.Vector2(this.wrap(1, 5), this.wrap(1, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(2, 1), this.wrap(2, 2)), new THREE.Vector2(this.wrap(2, 3), this.wrap(2, 4)), new THREE.Vector2(this.wrap(2, 5), this.wrap(2, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(3, 1), this.wrap(3, 2)), new THREE.Vector2(this.wrap(3, 3), this.wrap(3, 4)), new THREE.Vector2(this.wrap(3, 5), this.wrap(3, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(4, 1), this.wrap(4, 2)), new THREE.Vector2(this.wrap(4, 3), this.wrap(4, 4)), new THREE.Vector2(this.wrap(4, 5), this.wrap(4, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(5, 1), this.wrap(5, 2)), new THREE.Vector2(this.wrap(5, 3), this.wrap(5, 4)), new THREE.Vector2(this.wrap(5, 5), this.wrap(5, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(6, 1), this.wrap(6, 2)), new THREE.Vector2(this.wrap(6, 3), this.wrap(6, 4)), new THREE.Vector2(this.wrap(6, 5), this.wrap(6, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(7, 1), this.wrap(7, 2)), new THREE.Vector2(this.wrap(7, 3), this.wrap(7, 4)), new THREE.Vector2(this.wrap(7, 5), this.wrap(7, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(8, 1), this.wrap(8, 2)), new THREE.Vector2(this.wrap(8, 3), this.wrap(8, 4)), new THREE.Vector2(this.wrap(8, 5), this.wrap(8, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(9, 1), this.wrap(9, 2)), new THREE.Vector2(this.wrap(9, 3), this.wrap(9, 4)), new THREE.Vector2(this.wrap(9, 5), this.wrap(9, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(10, 1), this.wrap(10, 2)), new THREE.Vector2(this.wrap(10, 3), this.wrap(10, 4)), new THREE.Vector2(this.wrap(10, 5), this.wrap(10, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(11, 1), this.wrap(11, 2)), new THREE.Vector2(this.wrap(11, 3), this.wrap(11, 4)), new THREE.Vector2(this.wrap(11, 5), this.wrap(11, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(12, 1), this.wrap(12, 2)), new THREE.Vector2(this.wrap(12, 3), this.wrap(12, 4)), new THREE.Vector2(this.wrap(12, 5), this.wrap(12, 6))]);
				break;

			default:
		}
	}

	tetrahedron = (vertices, material = 0) => {
		// vertices
		this.geometry.vertices.push(new THREE.Vector3(vertices[0][0], vertices[0][1], vertices[0][2]));
		this.geometry.vertices.push(new THREE.Vector3(vertices[1][0], vertices[1][1], vertices[1][2]));
		this.geometry.vertices.push(new THREE.Vector3(vertices[2][0], vertices[2][1], vertices[2][2]));
		this.geometry.vertices.push(new THREE.Vector3(vertices[3][0], vertices[3][1], vertices[3][2]));

		this.geometry.vertices.push(new THREE.Vector3(vertices[4][0], vertices[4][1], vertices[4][2]));
		this.geometry.vertices.push(new THREE.Vector3(vertices[5][0], vertices[5][1], vertices[5][2]));
		this.geometry.vertices.push(new THREE.Vector3(vertices[6][0], vertices[6][1], vertices[6][2]));
		this.geometry.vertices.push(new THREE.Vector3(vertices[7][0], vertices[7][1], vertices[7][2]));

		// faces
		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 2, this.vi + 1));
		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 3, this.vi + 2));

		this.geometry.faces.push(new THREE.Face3(this.vi + 5, this.vi + 7, this.vi + 4));
		this.geometry.faces.push(new THREE.Face3(this.vi + 5, this.vi + 6, this.vi + 7));

		this.geometry.faces.push(new THREE.Face3(this.vi + 3, this.vi + 4, this.vi + 7));
		this.geometry.faces.push(new THREE.Face3(this.vi + 3, this.vi + 0, this.vi + 4));

		this.geometry.faces.push(new THREE.Face3(this.vi + 1, this.vi + 6, this.vi + 5));
		this.geometry.faces.push(new THREE.Face3(this.vi + 1, this.vi + 2, this.vi + 6));

		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 5, this.vi + 4));
		this.geometry.faces.push(new THREE.Face3(this.vi + 0, this.vi + 1, this.vi + 5));

		this.geometry.faces.push(new THREE.Face3(this.vi + 2, this.vi + 7, this.vi + 6));
		this.geometry.faces.push(new THREE.Face3(this.vi + 2, this.vi + 3, this.vi + 7));

		this.vi += 8;

		// faces material index
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 1;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 1;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 0;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 0;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 3;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 3;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 2;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 2;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 4;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 4;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 5;
		this.geometry.faces[this.fi++].materialIndex = (material * 6) + 5;

		// faces vertex
		switch (this.args.unwrap) {
			case 'fill':
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));

				this.geometry.faceVertexUvs[0].push(this.fill('lb', 1));
				this.geometry.faceVertexUvs[0].push(this.fill('lb', 2));
				break;

			case 'wrap':
				this.vertices = [0, 0.1, 0, 4, 0, 0.1];

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(1, 1), this.wrap(1, 2)), new THREE.Vector2(this.wrap(1, 3), this.wrap(1, 4)), new THREE.Vector2(this.wrap(1, 5), this.wrap(1, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(2, 1), this.wrap(2, 2)), new THREE.Vector2(this.wrap(2, 3), this.wrap(2, 4)), new THREE.Vector2(this.wrap(2, 5), this.wrap(2, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(3, 1), this.wrap(3, 2)), new THREE.Vector2(this.wrap(3, 3), this.wrap(3, 4)), new THREE.Vector2(this.wrap(3, 5), this.wrap(3, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(4, 1), this.wrap(4, 2)), new THREE.Vector2(this.wrap(4, 3), this.wrap(4, 4)), new THREE.Vector2(this.wrap(4, 5), this.wrap(4, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(5, 1), this.wrap(5, 2)), new THREE.Vector2(this.wrap(5, 3), this.wrap(5, 4)), new THREE.Vector2(this.wrap(5, 5), this.wrap(5, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(6, 1), this.wrap(6, 2)), new THREE.Vector2(this.wrap(6, 3), this.wrap(6, 4)), new THREE.Vector2(this.wrap(6, 5), this.wrap(6, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(7, 1), this.wrap(7, 2)), new THREE.Vector2(this.wrap(7, 3), this.wrap(7, 4)), new THREE.Vector2(this.wrap(7, 5), this.wrap(7, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(8, 1), this.wrap(8, 2)), new THREE.Vector2(this.wrap(8, 3), this.wrap(8, 4)), new THREE.Vector2(this.wrap(8, 5), this.wrap(8, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(9, 1), this.wrap(9, 2)), new THREE.Vector2(this.wrap(9, 3), this.wrap(9, 4)), new THREE.Vector2(this.wrap(9, 5), this.wrap(9, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(10, 1), this.wrap(10, 2)), new THREE.Vector2(this.wrap(10, 3), this.wrap(10, 4)), new THREE.Vector2(this.wrap(10, 5), this.wrap(10, 6))]);

				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(11, 1), this.wrap(11, 2)), new THREE.Vector2(this.wrap(11, 3), this.wrap(11, 4)), new THREE.Vector2(this.wrap(11, 5), this.wrap(11, 6))]);
				this.geometry.faceVertexUvs[0].push([new THREE.Vector2(this.wrap(12, 1), this.wrap(12, 2)), new THREE.Vector2(this.wrap(12, 3), this.wrap(12, 4)), new THREE.Vector2(this.wrap(12, 5), this.wrap(12, 6))]);
				break;

			default:
		}
	}

	fill = (type, f) => {
		switch (type) {
			case 'lb':
				switch (f) {
					case 1:
						return [new THREE.Vector2(0, 0), new THREE.Vector2(1, 1), new THREE.Vector2(0, 1)];

					case 2:
						return [new THREE.Vector2(0, 0), new THREE.Vector2(1, 0), new THREE.Vector2(1, 1)];

					default:
				}
				break;

			default:
		}

		return [new THREE.Vector2(0, 0), new THREE.Vector2(0, 0), new THREE.Vector2(0, 0)];
	}

	wrap = (f, v) => {
		const w = this.vertices[1] - this.vertices[0];
		const h = this.vertices[3] - this.vertices[2];
		const d = this.vertices[5] - this.vertices[4];

		const vector = `${f}_${v}`;

		let offset = 0;
		let length = 0;

		switch (vector) {
			case '1_1':
				offset = this.vertices[0];
				break;

			case '1_2':
				offset = this.vertices[2] + d;
				break;

			case '1_3':
				offset = this.vertices[0];
				length = w;
				break;

			case '1_4':
				offset = this.vertices[2] + d;
				length = -d;
				break;

			case '1_5':
				offset = this.vertices[0];
				break;

			case '1_6':
				offset = this.vertices[2] + d;
				length = -d;
				break;

			case '2_1':
				offset = this.vertices[0];
				break;

			case '2_2':
				offset = this.vertices[2] + d;
				break;

			case '2_3':
				offset = this.vertices[0];
				length = w;
				break;

			case '2_4':
				offset = this.vertices[2] + d;
				break;

			case '2_5':
				offset = this.vertices[0];
				length = w;
				break;

			case '2_6':
				offset = this.vertices[2] + d;
				length = -d;
				break;

			case '3_1':
				offset = this.vertices[0];
				break;

			case '3_2':
				offset = this.vertices[2] + h;
				break;

			case '3_3':
				offset = this.vertices[0];
				length = w;
				break;

			case '3_4':
				offset = this.vertices[2] + h;
				length = d;
				break;

			case '3_5':
				offset = this.vertices[0];
				break;

			case '3_6':
				offset = this.vertices[2] + h;
				length = d;
				break;

			case '4_1':
				offset = this.vertices[0];
				break;

			case '4_2':
				offset = this.vertices[2] + h;
				break;

			case '4_3':
				offset = this.vertices[0];
				length = w;
				break;

			case '4_4':
				offset = this.vertices[2] + h;
				break;

			case '4_5':
				offset = this.vertices[0];
				length = w;
				break;

			case '4_6':
				offset = this.vertices[2] + h;
				length = d;
				break;

			case '5_1':
				offset = this.vertices[1];
				break;

			case '5_2':
				offset = this.vertices[2];
				break;

			case '5_3':
				offset = this.vertices[1];
				length = -w;
				break;

			case '5_4':
				offset = this.vertices[2];
				length = h;
				break;

			case '5_5':
				offset = this.vertices[1];
				break;

			case '5_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '6_1':
				offset = this.vertices[1];
				break;

			case '6_2':
				offset = this.vertices[2];
				break;

			case '6_3':
				offset = this.vertices[1];
				length = -w;
				break;

			case '6_4':
				offset = this.vertices[2];
				break;

			case '6_5':
				offset = this.vertices[1];
				length = -w;
				break;

			case '6_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '7_1':
				offset = this.vertices[0];
				break;

			case '7_2':
				offset = this.vertices[2];
				break;

			case '7_3':
				offset = this.vertices[0];
				length = w;
				break;

			case '7_4':
				offset = this.vertices[2];
				length = h;
				break;

			case '7_5':
				offset = this.vertices[0];
				break;

			case '7_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '8_1':
				offset = this.vertices[0];
				break;

			case '8_2':
				offset = this.vertices[2];
				break;

			case '8_3':
				offset = this.vertices[0];
				length = w;
				break;

			case '8_4':
				offset = this.vertices[2];
				break;

			case '8_5':
				offset = this.vertices[0];
				length = w;
				break;

			case '8_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '9_1':
				offset = this.vertices[4];
				break;

			case '9_2':
				offset = this.vertices[2];
				break;

			case '9_3':
				offset = this.vertices[4];
				length = -d;
				break;

			case '9_4':
				offset = this.vertices[2];
				length = h;
				break;

			case '9_5':
				offset = this.vertices[4];
				break;

			case '9_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '10_1':
				offset = this.vertices[4];
				break;

			case '10_2':
				offset = this.vertices[2];
				break;

			case '10_3':
				offset = this.vertices[4];
				length = -d;
				break;

			case '10_4':
				offset = this.vertices[2];
				break;

			case '10_5':
				offset = this.vertices[4];
				length = -d;
				break;

			case '10_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '11_1':
				offset = this.vertices[0] + w;
				break;

			case '11_2':
				offset = this.vertices[2];
				break;

			case '11_3':
				offset = this.vertices[0] + w;
				length = d;
				break;

			case '11_4':
				offset = this.vertices[2];
				length = h;
				break;

			case '11_5':
				offset = this.vertices[0] + w;
				break;

			case '11_6':
				offset = this.vertices[2];
				length = h;
				break;

			case '12_1':
				offset = this.vertices[0] + w;
				break;

			case '12_2':
				offset = this.vertices[2];
				break;

			case '12_3':
				offset = this.vertices[0] + w;
				length = d;
				break;

			case '12_4':
				offset = this.vertices[2];
				break;

			case '12_5':
				offset = this.vertices[0] + w;
				length = d;
				break;

			case '12_6':
				offset = this.vertices[2];
				length = h;
				break;

			default:
				break;
		}

		const unit = v % 2 === 1 ? this.args.unit.x : this.args.unit.y;

		return offset / unit + length / unit;
	}

	mix = (counts, data, materials) => {
		let material = 0;

		switch (data.type) {
			case 'top':
				const n = counts.all - counts.current;

				if (n >= data.variant.offset && n < data.variant.offset + data.variant.amount) {
					material = 0;
				} else {
					material = 1;
				}
				break;

			default:
				material = 0;
				break;
		}

		return materials[material];
	}
}


export default Geometry;