import 'es6-promise-promise';

import React from 'react';
import ReactDOM from 'react-dom/client';

import Root from 'Root';


const root = ReactDOM.createRoot(
	document.getElementById('app'),
);

root.render(<Root />);