class Fit {
	constructor(instance, three, view, callback) {
		this.instance = instance;
		this.three = three;

		this.view = view;
		this.callback = callback;

		this.axis = 'y';
		this.stages = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150];

		this.camera = this.instance.three.cameras[this.view];
		this.controls = this.camera.controls;

		this.stage = {
			temp: -1,
			current: 0,
		};

		this.position = {
			temp: { x: 0, y: 0, z: 0 },
			current: { x: 0, y: 0, z: 0 },
		};

		this.init();
	}

	init = () => {
		this.action();

		this.controls.addEventListener('change', () => {
			this.action();
		});
	}

	action = () => {
		this.position.temp.x = this.position.current.x;
		this.position.temp.y = this.position.current.y;
		this.position.temp.z = this.position.current.z;

		this.position.current.x = this.camera.position.x;
		this.position.current.y = this.camera.position.y;
		this.position.current.z = this.camera.position.z;

		if (this.position.current[this.axis] !== this.position.temp[this.axis]) {
			if (this.stages) {
				this.stage.current = this.getStage();

				if (this.stage.current !== this.stage.temp) {
					this.stage.temp = this.stage.current;

					this.dispatchCallback();
				}
			} else {
				this.dispatchCallback();
			}
		}
	}

	getStage = () => {
		const value = Math.ceil(this.position.current[this.axis]);

		let result = 0;

		for (let stage = 0; stage < this.stages.length; stage++) {
			if (value >= this.stages[stage]) {
				result = stage;
			} else {
				break;
			}
		}

		return result;
	}

	dispatchCallback = () => {
		if (this.callback) {
			this.callback(this.position.current[this.axis], this.stage.current);
		}
	}
}


export default Fit;