import * as THREE from 'three';

import Objects from 'classes/Tools/Objects';

import * as actions from 'modules/App/redux/actions';
import * as panelActions from '../redux/panel/actions';

import ThreeBuffer from 'classes/Three/ThreeBuffer';
import ThreeRaycaster from 'classes/Three/ThreeRaycaster';
import ThreeFit from 'classes/Three/ThreeFit';
import Colors from 'classes/Tools/Colors';
import Maths from 'classes/Tools/Maths';
import GTM from 'classes/GTM';

import Core from './Core';
import Actions from './Actions';

import Episode from './objects/Episode';
import House from './objects/House';


class App extends Core {
	constructor(args) {
		super(args);

		this.actions = new Actions(this);

		this.buffer = new ThreeBuffer(null, () => {
			this.projectReload();
		});

		// systems
		this.system = null;

		// settings
		this.settings = {
			ground: 'grass',

			height: null,
			profile: {
				material: null,
				color: null,
				mix: null,
			},
			pole: {
				material: null,
				color: null,
			},
			wall: {
				material: null,
			},
			average: false,

			direction: 'left',
			side: 'in',
		};

		// objects
		this.objects = [];
		this.episodes = [];

		this.firstEpisode = null;
		this.lastEpisode = null;

		this.cornerTolerance = 0.08;

		this.init();
		this.events();
	}

	init = () => {
		this.loadScenes();
		this.loadTextures();
		this.loadMaterials();
		this.loadEvents();

		this.addHouse();
		this.addRaycaster();
		this.addFit();

		if (!this.project.data) {
			// system
			this.system = 'modern';

			// settings
			this.settings.height = this.getSystem().default.height;

			this.settings.profile.material = this.getSystem().profile.default.material;
			this.settings.profile.color = this.getSystem().profile.default.color;
			this.settings.profile.mix = this.getSystem().profile.default.mix;

			this.settings.pole.material = this.getSystem().pole.default.material;
			this.settings.pole.color = this.getSystem().pole.default.color;

			this.settings.wall.material = this.getSystem().wall.default.material;

			this.updateTextures();
		} else {
			this.extensions.storage.load(this.project);
		}

		if (this.three.environment.objects[this.settings.ground]) {
			this.three.environment.objects[this.settings.ground].visible = true;
		}

		this.actions.setPanel('settings');
	}

	events = () => {
		['keydown'].forEach((event) => document.addEventListener(event, (e) => {
			// set pole materials index
			if (e.keyCode >= 48 && e.keyCode <= 57) {
				const i = e.keyCode !== 48 ? e.keyCode - 49 : 9;
				const material = Objects.keys(this.getSystem().pole.materials)[i];

				if (material) {
					this.actions.setSystemSetting('pole', 'material', material);
				}
			}
		}));
	}

	setView = (view, auto = false) => {
		if (view === this.three.view.current) {
			return;
		}

		if (auto) {
			this.three.view.set(view);
		} else {
			this.reloadContext(() => {
				this.three.view.set(view);
			});
		}

		switch (view) {
			case '2d':
				if (this.raycaster) {
					this.raycaster.setStatus(this.mode === 'modify');
				}
				break;

			case '3d':
				if (this.raycaster) {
					this.raycaster.setStatus(false);
				}

				GTM.send(null, '3DView', {}, true);
				break;

			default:
		}

		panelActions.setView(view);
	}

	reloadContext = (callback) => {
		actions.set('loading', 'Trwa przeładowywanie widoku...');

		setTimeout(() => {
			if (callback) {
				callback();
			}

			this.three.reset();

			setTimeout(() => {
				actions.set('loading', false);
			}, 100);
		}, 400);
	}


	/* --- HOUSE ---------------------------------------------- */

	addHouse = () => {
		this.objects.house = new House(this);
	}


	/* --- RAYCASTER ------------------------------------------ */

	addRaycaster = () => {
		this.intersects = [];

		this.raycaster = new ThreeRaycaster(this, this.three, {
			canvas: this.canvas[0].children[0],
			workspace: this.three.environment.objects.map,
			intersects: this.intersects,
			status: this.mode === 'modify' && this.three.view.get() === '2d',
		});

		this.raycaster.filter = (position, hook) => {
			const data = this.getEpisodeData(position, hook);

			return data;
		};

		this.raycaster.success = (position, hook) => {
			const data = this.getEpisodeData(position, hook);

			const params = {
				position: { start: position[0], end: position[1] },
				hook: { start: hook[0] ? { id: hook[0].appid, name: hook[0].appname } : null, end: hook[1] ? { id: hook[1].appid, name: hook[1].appname } : null },
			};

			switch (panelActions.getMode()) {
				case 'episode':
					if (data.width >= this.getSystem().wall.settings.minWidth) {
						this.addEpisode(null, 'fence', params.hook, params.position);
					}
					break;

				case 'gateSliding':
					this.addEpisode(null, 'gateSliding', params.hook, params.position, { width: this.settings.width, direction: this.settings.direction, side: this.settings.side });
					break;

				case 'gateSwing':
					this.addEpisode(null, 'gateSwing', params.hook, params.position, { width: this.settings.width });
					break;

				case 'wicket':
					this.addEpisode(null, 'wicket', params.hook, params.position, { width: this.settings.width, direction: this.settings.direction });
					break;

				case 'house':
					this.objects.house.apply();
					break;

				case 'remove':
					this.removeEpisodesByHook(hook[0].appid, hook[0].appname);
					break;

				default:
					break;
			}
		};

		this.raycaster.hover = (intersect) => {
			intersect.material[1] = this.materials.pointactive;
		};

		this.raycaster.unhover = (intersect) => {
			intersect.material[1] = this.materials.point;
		};

		this.actions.setMode('episode');
	}

	updateIntersects = () => {
		this.intersects = [];

		this.raycaster.init(this.intersects);

		Objects.values(this.episodes).forEach((p) => {
			this.intersects.push(p.objects.start);
			this.intersects.push(p.objects.end);
		});
	}


	/* --- FIT ------------------------------------------------ */

	addFit = () => {
		this.fit = new ThreeFit(this, this.three, '2d', (position) => {
			this.font.size = position * 0.012 + 0.25;

			if (this.raycaster && this.raycaster.during) {
				this.raycaster.doDescription();
			}

			Objects.values(this.episodes).forEach((p) => {
				p.doStart();
				p.doEnd();
				p.doDescription();
			});
		});
	}


	/* --- SYSTEMS -------------------------------------------- */

	getSystemDir = () => `${this.config.systemsDir}${this.system}/`

	getSystem = () => this.config.systems[this.system]

	getPoleMaterial = () => this.getSystem().pole.materials[this.settings.pole.material]

	getWallMaterial = () => this.getSystem().wall.materials[this.settings.wall.material]

	updateTextures = (lockReload = false) => {
		let reload = true;

		if (!this.textures.system) {
			this.textures.system = {};
		}

		['profile', 'pole'].forEach((indicator) => {
			if (!(indicator in this.textures.system)) {
				this.textures.system[indicator] = [];
			}

			const cid = this.settings[indicator].color;
			const color = this.getSystem()[indicator].colors[cid];

			if (!(cid in this.textures.system[indicator])) {
				this.textures.system[indicator][cid] = [];

				Objects.entries(color.variants).forEach(([vid, variant]) => {
					if (variant.type === 'texture') {
						if (!(vid in this.textures.system[indicator][cid])) {
							let source = `${this.getSystemDir()}${variant.source}`;

							let texture = this.createTexture(source, true);
							texture.needsUpdate = true;
							texture.wrapS = THREE.RepeatWrapping;
							texture.wrapT = THREE.RepeatWrapping;
							texture.anisotropy = 8;

							this.textures.system[indicator][cid][vid] = texture;

							reload = !lockReload;
						}
					}
				});
			}
		});

		if (reload) {
			this.projectReload();
		}
	}

	updateSystemMaterials = () => {
		let profiles = [];
		let poles;

		Objects.entries(this.getSystem().profile.colors[this.settings.profile.color].variants).forEach(([vid, variant]) => {
			switch (variant.type) {
				case 'color':
					const colors = Colors.shades(variant.source, 15);

					if (vid === 'secondary') {
						profiles.mixes = { color: colors[0], metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5 };
					}

					if (vid === 'primary') {
						profiles.panels = { color: colors[0], metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5 };
						profiles.slats = { color: colors[1], metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5 };
						profiles.frames = { color: colors[2], metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5 };
					}
					break;

				case 'texture':
					const texture = this.textures.system.profile[this.settings.profile.color].secondary;

					if (vid === 'secondary') {
						profiles.mixes = {
							map: texture, aoMap: texture, aoMapIntensity: -0.5, metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5,
						};
					}

					if (vid === 'primary') {
						profiles.panels = {
							map: texture, aoMap: texture, aoMapIntensity: -0.5, metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5,
						};

						profiles.slats = {
							map: texture, aoMap: texture, aoMapIntensity: 0, metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5,
						};

						profiles.frames = {
							map: texture, aoMap: texture, aoMapIntensity: 0.5, metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5,
						};
					}
					break;

				default:
			}
		});

		Objects.entries(this.getSystem().pole.colors[this.settings.pole.color].variants).forEach(([vid, variant]) => {
			switch (variant.type) {
				case 'color':
					const colors = Colors.shades(variant.source, 15);

					if (vid === 'default') {
						poles = { color: colors[1], metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5 };
					}
					break;

				case 'texture':
					const texture = this.textures.system.pole[this.settings.pole.color].default;

					if (vid === 'primary') {
						poles = {
							map: texture, aoMap: texture, aoMapIntensity: 0, metalness: variant.metalness, roughness: variant.roughness, envMap: variant.roughness < 0.5 ? this.envMapVirtual : false, envMapIntensity: 0.5,
						};
					}
					break;

				default:
			}
		});

		this.systemMaterials = {
			profiles: {
				mixes: new THREE.MeshStandardMaterial(profiles.mixes),
				panels: new THREE.MeshStandardMaterial(profiles.panels),
				slats: new THREE.MeshStandardMaterial(profiles.slats),
				frames: new THREE.MeshStandardMaterial(profiles.frames),
			},
			poles: new THREE.MeshStandardMaterial(poles),
			holders: this.materials.chrome,
			roofs: new THREE.MeshStandardMaterial({ color: '#222222', metalness: 0, roughness: 0.5 }),
			concretes: {
				tops: new THREE.MeshBasicMaterial({ color: '#aaaaaa', metalness: 0, roughness: 1 }),
				sides: new THREE.MeshBasicMaterial({ color: '#999999', metalness: 0, roughness: 1 }),
			},
			errors: new THREE.MeshStandardMaterial({ color: '#ff0000', transparent: true, opacity: 0.3 }),
		};
	}


	/* --- METHODS -------------------------------------------- */

	addEpisode = (id, kind, hook, position, config = {}) => {
		// id
		id = parseInt(id, 10) || (this.lastEpisode ? parseInt(this.lastEpisode.id, 10) + 1 : 0);

		// episode
		let episode = new Episode(this, id, kind, hook, position, config);

		this.episodes[id] = episode;

		if (this.firstEpisode === null) {
			this.firstEpisode = episode;
		}

		this.lastEpisode = episode;

		this.setModified();
		this.projectReload();

		if (!this.isLoading) {
			GTM.send(null, 'Episode', {}, true);
		}
	}

	removeEpisode = (id) => {
		let episode = this.episodes[id];

		if (episode) {
			let count = -1;
			Objects.values(this.episodes).forEach(() => {
				count++;
			});

			// first & last
			if (this.firstEpisode && this.firstEpisode.id === id) {
				this.firstEpisode = episode.next;
			}

			if (this.lastEpisode && this.lastEpisode.id === id) {
				this.lastEpisode = episode.prev;
			}

			// prev & next
			if (episode.prev) {
				episode.prev.next = episode.next;
			}

			if (episode.next) {
				episode.next.prev = episode.prev;
			}

			// first & last set null
			if (count < 1) this.firstEpisode = null;
			if (count < 1) this.lastEpisode = null;

			// remove
			episode.remove();
			delete this.episodes[id];

			this.updateIntersects();
		}

		this.setModified();
		this.projectReload();
	}

	removeEpisodesByHook = (id, name) => {
		Objects.values(this.episodes).forEach((p) => {
			if (p.hook.start?.id === id) {
				if (name && p.hook.start?.name === name) {
					this.removeEpisodesByHook(p.id, false);
				} else {
					p.hook.start = null;
					p.reload();
				}
			}

			if (p.hook.end?.id === id) {
				if (name && p.hook.end?.name === name) {
					this.removeEpisodesByHook(p.id, false);
				} else {
					p.hook.end = null;
					p.reload();
				}
			}
		});

		this.removeEpisode(id);
	}

	projectReload = () => {
		this.updateSystemMaterials();

		if (this.objects.house.status) {
			this.objects.house.do();
		}

		Objects.values(this.episodes).forEach((p) => {
			p.reload();
		});
	}


	/* --- FUNCTIONS ------------------------------------------ */

	getEpisodeData = (position, hook) => {
		const A = { x: position[0].x, y: position[0].z };
		const B = { x: position[1].x, y: position[1].z };

		const start = !hook[0] ? 1 : 0;
		const end = !hook[1] ? 1 : 0;

		const poleMaterial = this.getPoleMaterial();
		const diff = poleMaterial.width;
		const width = Maths.getDistance(A, B);
		const widthCustom = width + (!start ? -1 : 1) * poleMaterial.width * 0.5 + (!end ? -1 : 1) * poleMaterial.width * 0.5;
		const widthInner = widthCustom - (start ? poleMaterial.width : 0) - (end ? poleMaterial.width : 0);
		const widthFull = widthInner + diff * 2;
		const rotation = Maths.getRotation(A, B);
		const center = Maths.getCenter(A, B);

		return {
			diff,
			width,
			widthCustom,
			widthInner,
			widthFull,
			rotation,
			center: { x: center.x, y: 0, z: center.y },
		};
	}
}


export default App;