import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

import container from 'redux/container';

import Page404 from 'views/Page404';


class RoutesList extends React.Component {
	element = (component, auth = null) => {
		if (this.auth(auth)) {
			if (!container?.user) {
				return this.redirect(auth);
			}
		}

		return component;
	}

	auth = (auth) => {
		if (auth === null) {
			if (container.config.auth?.default !== undefined) {
				return !!container.config.auth.default;
			}

			return false;
		}

		return !!auth;
	}

	redirect = (auth) => {
		let path;

		if (typeof auth === 'string') {
			path = auth;
		} else {
			path = (container.config.auth?.redirect) ? container.config.auth.redirect : '/';
		}

		return (
			<Navigate to={path} />
		);
	}

	render() {
		return (
			<BrowserRouter basename={window.basepath}>
				<Routes>
					{container.routes.map((route) => (
						<Route
							exact
							path={route.path}
							element={this.element(route.component, route.auth)}
							key={route.path}
						/>
					))}

					<Route exact path="*" element={this.element(<Page404 />)} />
				</Routes>
			</BrowserRouter>
		);
	}
}


export default RoutesList;