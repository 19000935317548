import React from 'react';
import classNames from 'classnames';

import * as actions from 'modules/App/redux/actions';


const ButtonHelp = () => {
	return (
		<button
			onClick={() => { actions.bar(!actions.get('bar')); }}
			type="button"
			className={classNames(
				'ts-header__bk group',
				!actions.get('bar') ? '--reverse' : null,
			)}
		>
			<span className="ts-header__bk-i icon-slide-left" />
		</button>
	);
};


export default ButtonHelp;