import Geometry from 'classes/Three/Geometry';
import Helper from 'classes/Tools/Helper';


class EpisodeGeometry extends Geometry {
	constructor(data) {
		super({
			unwrap: 'wrap',
		});

		let diff1;
		let diff2;

		let w1;
		let w2;

		let wx1;
		let wx2;
		let hx1;
		let hx2;

		let concrete = data.wall.space >= 0.06 ? data.wall.space - 0.05 : 0;


		/* --- POLES ---------------------------------------------- */

		if (!data.sketch) {
			for (let i = 1; i <= data.counts.poles; i++) {
				const cx = (i === 1 && data.ending.start) || (i === data.counts.poles && data.ending.end) ? 0 : concrete;

				// const halfW1 = (i === 1 && data.half.start) ? data.pole.width / 2 : 0;
				// const halfW2 = (i === 1 && data.half.start) || (i === data.counts.poles && data.half.end) ? data.pole.width / 2 : 0;

				w1 = this.polePosition(data, i) - this.poleCenter(data, data.pole.width * 0.5, 'start') - this.wallCenter(data, data.pole.width * 0.5, 'start') + 0;
				w2 = w1 + data.pole.width - 0;

				// poles
				this.rect(w1, w2, cx, data.pole.height - Helper.aN(data.pole.roof?.height), -data.pole.depth / 2, data.pole.depth / 2, 4);

				// roofs
				if (data.pole.roof) {
					this.rect(w1 - (data.pole.roof.width - data.pole.width) / 2, w2 + (data.pole.roof.width - data.pole.width) / 2, data.pole.height - data.pole.roof.height, data.pole.height, -data.pole.roof.depth / 2, data.pole.roof.depth / 2, 6);
				}

				// bases
				if (data.pole.base) {
					this.rect(w1 - (data.pole.base.width - data.pole.width) / 2, w2 + (data.pole.base.width - data.pole.width) / 2, cx, cx + data.pole.base.height, -data.pole.base.depth / 2, data.pole.base.depth / 2, 4);
				}
			}
		}


		/* --- WALLS ---------------------------------------------- */

		if (!data.sketch && !data.manufacture) {
			for (let i = 1; i <= data.counts.walls; i++) {
				const wallPosition = this.wallPosition(data, i);

				w1 = wallPosition[0] - this.poleCenter(data, data.pole.width * 0.5, 'start') - this.wallCenter(data, data.pole.width * 0.5, 'start');
				w2 = w1 + wallPosition[1];

				if (Math.ceil(w2 - w1) >= data.wall.minWidth) {
					// holders
					if (data.wall.holder) {
						for (let x = 1; x <= 4; x++) {
							switch (x) {
								// left bottom
								case 1:
									wx1 = w1;

									hx1 = data.wall.space + data.wall.holder.space;
									hx2 = hx1 + data.wall.holder.height;
									break;

								// left top
								case 2:
									wx1 = w1;

									hx1 = data.wall.space + data.wall.height - data.wall.holder.space - data.wall.holder.height;
									hx2 = hx1 + data.wall.holder.height;
									break;

								// right bottom
								case 3:
									wx1 = w2 - data.wall.holder.width;

									hx1 = data.wall.space + data.wall.holder.space;
									hx2 = hx1 + data.wall.holder.height;
									break;

								// right top
								case 4:
									wx1 = w2 - data.wall.holder.width;

									hx1 = data.wall.space + data.wall.height - data.wall.holder.space - data.wall.holder.height;
									hx2 = hx1 + data.wall.holder.height;
									break;

								default:
							}

							this.rect(wx1, wx1 + data.wall.holder.width, hx1, hx2, -data.wall.holder.depth / 2, data.wall.holder.depth / 2, 5);
						}
					}

					// frame
					if (data.wall.frame) {
						for (let x = 1; x <= 4; x++) {
							switch (x) {
								// left
								case 1:
									diff1 = Helper.aN(data.wall.holder?.width);

									wx1 = w1 + diff1;
									wx2 = wx1 + data.wall.frame.width;

									hx1 = data.wall.space;
									hx2 = hx1 + data.wall.height;
									break;

								// right
								case 2:
									diff1 = Helper.aN(data.wall.holder?.width);

									wx1 = w2 - diff1 - data.wall.frame.width;
									wx2 = wx1 + data.wall.frame.width;

									hx1 = data.wall.space;
									hx2 = hx1 + data.wall.height;
									break;

								// top
								case 3:
									diff1 = Helper.aN(data.wall.holder?.width);

									wx1 = w1 + diff1 + data.wall.frame.width;
									wx2 = w2 - diff1 - data.wall.frame.width;

									hx1 = data.wall.space + data.wall.height - data.wall.frame.height;
									hx2 = hx1 + data.wall.frame.height;
									break;

								// bottom
								case 4:
									diff1 = Helper.aN(data.wall.holder?.width);

									wx1 = w1 + diff1 + data.wall.frame.width;
									wx2 = w2 - diff1 - data.wall.frame.width;

									hx1 = data.wall.space;
									hx2 = hx1 + data.wall.frame.height;
									break;

								default:
							}

							this.rect(wx1, wx2, hx1, hx2, -data.wall.frame.depth / 2, data.wall.frame.depth / 2, 3);
						}
					}

					// slats
					if (data.wall.slat) {
						hx1 = data.wall.space;
						hx2 = hx1 + data.wall.height + Helper.aN(data.wall.slat.roof.height);

						diff1 = Helper.aN(data.wall.holder?.width) + Helper.aN(data.wall.frame?.width);
						diff2 = Helper.aN(data.wall.frame?.height);

						for (let x = 1; x <= 2; x++) {
							switch (x) {
								// left
								case 1:
									wx1 = w1 + diff1;
									break;

								// right
								case 2:
									wx1 = w2 - diff1 - data.wall.slat.width;
									break;

								default:
							}

							this.rect(wx1, wx1 + data.wall.slat.width, hx1 + diff2, hx2 - diff2, -data.wall.slat.depth / 2, data.wall.slat.depth / 2, 2);
						}
					}

					// panels
					if (data.wall.panel) {
						diff1 = Helper.aN(data.wall.holder?.width) + Helper.aN(data.wall.frame?.width) + Helper.aN(data.wall.slat?.width);
						diff2 = Helper.aN(data.wall.frame?.height);

						wx1 = w1 + diff1;
						wx2 = w2 - diff1;

						for (let x = 1; x <= data.counts.panels; x++) {
							hx1 = data.wall.space + diff2 + ((x - 1) * (data.wall.panel.height + data.wall.panel.space.wall));

							this.rect(wx1, wx2, hx1, hx1 + data.wall.panel.height, -data.wall.panel.depth / 2, data.wall.panel.depth / 2, this.mix({ all: data.counts.panels, current: x }, data.mix, [0, 1]));
						}
					}

					// concretes
					let cx1 = 0;
					let cx2 = 0;

					if (i === 1) {
						cx1 = (data.ending.start ? -1 : 1) * (data.start ? 1 : -1);
					}

					if (i === data.counts.walls) {
						cx2 = (data.ending.end ? -1 : 1) * (data.end ? 1 : -1);
					}

					diff1 = (data.concrete.width / 2) * cx1;
					diff2 = (data.concrete.width / 2) * cx2;

					this.rect(w1 - data.pole.width / 2 - diff1, w2 + data.pole.width / 2 + diff2, 0, concrete, -data.concrete.depth / 2, data.concrete.depth / 2, 7);
				} else {
					hx1 = data.wall.space;
					hx2 = hx1 + data.wall.height;

					this.rect(w1, w2, hx1, hx2, -0.01 / 2, 0.01 / 2, 8);
				}
			}
		}


		/* --- SKETCH --------------------------------------------- */

		if (data.sketch) {
			// poles
			for (let i = 1; i <= data.counts.poles; i++) {
				w1 = this.polePosition(data, i) - this.poleCenter(data, data.pole.width * 0.5, 'start') - this.wallCenter(data, data.pole.width * 0.5, 'start');
				w2 = w1 + data.pole.width;

				this.rect(w1 + (data.pole.width - data.sketch) * 0.5, w2 - (data.pole.width - data.sketch) * 0.5, 0, data.pole.height, -data.sketch * 3 / 2, data.sketch * 3 / 2, 0);
			}

			// walls
			for (let i = 1; i <= data.counts.walls; i++) {
				const wallPosition = this.wallPosition(data, i);

				w1 = wallPosition[0] - this.poleCenter(data, data.pole.width * 0.5, 'start') - this.wallCenter(data, data.pole.width * 0.5, 'start');
				w2 = w1 + wallPosition[1];

				if (Math.ceil(w2 - w1) >= data.wall.minWidth) {
					this.rect(w1 - (data.pole.width - data.sketch) * 0.5, w2 + (data.pole.width - data.sketch) * 0.5, 0, data.pole.height, -data.sketch / 2, data.sketch / 2, 0);
				}
			}
		}

		this.geometry.computeFaceNormals();

		return this.geometry;
	}

	polePosition = (data, n) => {
		let result = 0;

		n = data.start ? n : n + 1;

		// poles
		result += (n - 1) * data.pole.width;

		// walls
		if (n >= 2) {
			for (let i = 2; i <= n; i++) {
				// normal
				if (i - 1 <= data.counts.walls - 2) {
					result += data.wall.width;
				}

				// prelast
				if (i - 1 === data.counts.walls - 1) {
					result += data.wall.prelast;
				}

				// last
				if (i - 1 === data.counts.walls) {
					result += data.wall.last;
				}
			}
		}

		// console.log(result);

		return result;
	}

	wallPosition = (data, n) => {
		let result = 0;

		// poles
		result += n * data.pole.width;

		// walls
		if (n >= 2) {
			for (let i = 2; i <= n; i++) {
				// normal
				if (i <= data.counts.walls - 2) {
					result += data.wall.width;
				}

				// prelast
				if (i === data.counts.walls - 1) {
					result += data.wall.width;
				}

				// last
				if (i === data.counts.walls) {
					result += data.wall.prelast;
				}
			}
		}

		let current = data.wall.width;

		if (n === data.counts.walls - 1) {
			current = data.wall.prelast;
		}

		if (n === data.counts.walls) {
			current = data.wall.last;
		}

		// console.log(`${result}, ${current}`);

		return [result, current];
	}

	poleCenter = (data, width, type) => (data[type] ? width : 0)

	wallCenter = (data, width, type) => (!data[type] ? width : 0)
}


export default EpisodeGeometry;