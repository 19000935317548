import download from 'downloadjs';

import * as actions from 'modules/App/redux/actions';


class Screenshot {
	constructor(instance) {
		this.instance = instance;
		this.three = instance.three;

		this.settings = {
			width: 3840,
			height: 2160,
		};
	}

	execute = () => {
		let { width, height } = this.settings;

		actions.set('loading', 'Trwa ładowanie...');
		this.three.render.domElement.style.opacity = 0;

		// set new view
		this.three.camera.aspect = width / height;
		this.three.camera.updateProjectionMatrix();
		this.three.render.setSize(width, height);

		setTimeout(() => {
			this.three.render.render(this.three.scene, this.three.camera);

			download(this.three.render.domElement.toDataURL('image/jpeg', 0.85), 'printscreen.jpg', 'image/jpeg');

			// restore default view
			this.three.camera.aspect = this.three.aspect;
			this.three.camera.updateProjectionMatrix();
			this.three.render.setSize(this.three.width, this.three.height);

			this.three.render.domElement.style.opacity = 1;
			actions.set('loading', false);
		}, 1000);
	}
}


export default Screenshot;