let _ = 'PROJECT/PANEL/';

const SET = `${_}SET`;
const RESET = `${_}RESET`;

const GET_VIEW = `${_}GET_VIEW`;
const SET_VIEW = `${_}SET_VIEW`;

const GET_MODE = `${_}GET_MODE`;
const SET_MODE = `${_}SET_MODE`;


export default {
	SET,
	RESET,

	GET_VIEW,
	SET_VIEW,

	GET_MODE,
	SET_MODE,
};