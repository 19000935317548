import React from 'react';
import PropTypes from 'prop-types';


const Button = (props) => {
	return (
		<div>
			<button onClick={props.action} type="button" className="ts-btni --sm">
				<span className={`icon-${props.type}`} />
				<span>{props.description}</span>
			</button>
		</div>
	);
};


Button.defaultProps = {
	action: null,
	description: null,
};

Button.propTypes = {
	action: PropTypes.func,
	type: PropTypes.oneOf(['camera', 'save', 'refresh']).isRequired,
	description: PropTypes.string,
};


export default Button;