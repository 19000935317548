import container from 'redux/container';

import store from 'redux/store';
import types from './types';


const get = (name) => store.getState().app[name];

const set = (name, value) => {
	store.dispatch({ type: types.SET, name, value });
};


const bar = (status) => {
	store.dispatch({ type: types.BAR, status });

	if (container.app) {
		container.app.reloadContext();
	}
};

const popup = (value) => {
	const className = 'body-layerOpened';

	if (value) {
		document.body.classList.add(className);
	} else {
		document.body.classList.remove(className);
	}

	store.dispatch({ type: types.SET, name: 'popup', value });
};

const modal = (content, title = null) => {
	store.dispatch({ type: types.SET, name: 'modal', value: content ? { title, content } : null });
};


export {
	get,
	set,

	bar,
	popup,
	modal,
};