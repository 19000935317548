import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Button = (props) => {
	return (
		<div onClick={props.action}>
			<div
				className={classNames(
					'ts-card',
					'group',
					props.menu === 'additional' ? '--hov' : null,
					props.active,
				)}
			>
				<span className="ts-card__i">
					<span className={`icon-${props.type}`} />
					<span className="ts-card__sh" />
				</span>
				<div className="ts-card__t">{props.description}</div>
			</div>
		</div>
	);
};


Button.defaultProps = {
	action: null,
	description: null,
	active: '',
};

Button.propTypes = {
	action: PropTypes.func,
	menu: PropTypes.oneOf(['default', 'additional']).isRequired,
	type: PropTypes.oneOf([
		'design', 'bridge', 'door', 'house',
		'align', 'delete',
	]).isRequired,
	description: PropTypes.string,
	active: PropTypes.string,
};


export default Button;