import Geometry from 'classes/Three/Geometry';
import Helper from 'classes/Tools/Helper';


class GateSlidingGeometry extends Geometry {
	constructor(width, height, data, direction, side) {
		super({
			unwrap: 'wrap',
		});

		let w1 = -width / 2 + 0.005;
		let w2 = width / 2 - 0.005;

		let d = (data.inside + data.frame.depth / 2) * (side === 'in' ? -1 : 1);

		let wx1;
		let wx2;
		let hx1;
		let hx2;
		let dx1;
		let dx2;


		/* --- FRAME ---------------------------------------------- */

		// vertical
		hx1 = data.frame.bottom.height + data.frame.height;
		hx2 = height - data.frame.height;

		dx1 = d - data.frame.depth / 2;
		dx2 = d + data.frame.depth / 2;

		for (let x = 1; x <= 3; x++) {
			switch (x) {
				// left
				case 1:
					wx1 = w1;
					wx2 = wx1 + data.frame.width;
					break;

				// right
				case 2:
					wx1 = w2 - data.frame.width;
					wx2 = w2;
					break;

				// center
				case 3:
					wx1 = -data.frame.center.width / 2;
					wx2 = data.frame.center.width / 2;

					dx1 = d - data.frame.center.depth / 2;
					dx2 = d + data.frame.center.depth / 2;
					break;

				default:
			}

			this.rect(wx1, wx2, hx1, hx2, dx1, dx2, 3);
		}

		// horizontal
		dx1 = d - data.frame.depth / 2;
		dx2 = d + data.frame.depth / 2;

		for (let x = 1; x <= 3; x++) {
			switch (x) {
				// top
				case 1:
					wx1 = w1;
					wx2 = w2;

					hx1 = height - data.frame.height;
					hx2 = height;
					break;

				// bottom
				case 2:
					wx1 = w1 - (direction === 'left' ? data.frame.extra : 0);
					wx2 = w2 + (direction === 'right' ? data.frame.extra : 0);

					hx1 = data.frame.bottom.height;
					hx2 = hx1 + data.frame.height;
					break;

				// bottom big
				case 3:
					wx1 = w1 - (direction === 'left' ? data.frame.extra : 0);
					wx2 = w2 + (direction === 'right' ? data.frame.extra : 0);

					hx1 = 0;
					hx2 = hx1 + data.frame.bottom.height;

					dx1 = d - data.frame.bottom.depth / 2;
					dx2 = d + data.frame.bottom.depth / 2;
					break;

				default:
			}

			this.rect(wx1, wx2, hx1, hx2, dx1, dx2, 3);
		}

		// diagonal
		let f1 = 0;
		let f2 = 0;

		switch (direction) {
			case 'left':
				wx1 = w1 - data.frame.extra + this.D;
				wx2 = w1 + data.frame.width - this.D;

				f1 = data.frame.width;
				break;

			case 'right':
				wx1 = w2 + data.frame.extra - this.D;
				wx2 = w2 - data.frame.width + this.D;

				f2 = data.frame.width;
				break;

			default:
		}

		hx1 = data.frame.bottom.height + data.frame.height;
		hx2 = height;

		dx1 = d - data.frame.depth / 2;
		dx2 = d + data.frame.depth / 2;

		this.tetrahedron([
			[wx1 - f2, hx1, dx1 + this.D],
			[wx1 - f2, hx1, dx2 - this.D],
			[wx1 + f1, hx1, dx2 - this.D],
			[wx1 + f1, hx1, dx1 + this.D],

			[wx2 - f1, hx2 - this.D, dx1 + this.D],
			[wx2 - f1, hx2 - this.D, dx2 - this.D],
			[wx2 + f2, hx2 - this.D, dx2 - this.D],
			[wx2 + f2, hx2 - this.D, dx1 + this.D],
		], 3);


		/* --- SLATS ---------------------------------------------- */

		hx1 = data.frame.bottom.height + data.frame.height;
		hx2 = height - data.frame.height;

		dx1 = d - data.slat.depth / 2;
		dx2 = d + data.slat.depth / 2;

		for (let x = 1; x <= 4; x++) {
			switch (x) {
				// part 1 - left
				case 1:
					wx1 = w1 + data.frame.width;
					break;

				// part 1 - right
				case 2:
					wx1 = -data.frame.center.width / 2 - data.slat.width;
					break;

				// part 2 - left
				case 3:
					wx1 = data.frame.center.width / 2;
					break;

				// part 2 - right
				case 4:
					wx1 = w2 - data.frame.width - data.slat.width;
					break;

				default:
			}

			this.rect(wx1, wx1 + data.slat.width, hx1, hx2, dx1, dx2, 2);
		}


		/* --- PANELS --------------------------------------------- */

		wx1 = w1 + data.frame.width;
		wx2 = w2 - data.frame.width;

		dx1 = d - data.panel.depth / 2;
		dx2 = d + data.panel.depth / 2;

		for (let x = 1; x <= data.counts.panels; x++) {
			hx1 = data.frame.bottom.height + data.frame.height + data.space + ((x - 1) * (data.panel.height + data.panel.space.gateSliding)) + this.getFraud(-x, data);
			hx2 = hx1 + data.panel.height + this.getFraud(x, data);

			this.rect(wx1, wx2, hx1, hx2, dx1, dx2, this.mix({ all: data.counts.panels, current: x }, data.mix, [0, 1]));
		}

		this.geometry.computeFaceNormals();

		return this.geometry;
	}

	getFraud = (x, data) => {
		if (x < -1) {
			return Helper.aN(data.fraud?.gateSliding?.top);
		}

		if (x === 1) {
			return Helper.aN(data.fraud?.gateSliding?.top);
		}

		if (x === data.counts.panels) {
			return Helper.aN(data.fraud?.gateSliding?.bottom);
		}

		return 0;
	}
}


export default GateSlidingGeometry;