import modules from 'modules/index';


class Modules {
	constructor() {
		this.reducers = [];
		this.views = [];

		this.init();
	}

	init = () => {
		modules.forEach((module) => {
			if (module.reducers) {
				this.reducers = {
					...this.reducers,
					...module.reducers,
				};
			}

			if (module.views) {
				let base = (module.base) ? module.base : '';

				this.view(module.views, base);
			}
		});
	}

	view = (source, base) => {
		source.forEach((view) => {
			const basePath = (view.route?.parent !== undefined) ? view.route.parent : base;
			const routePath = (view.route?.path !== undefined) ? view.route.path : '';

			const parent = `/${basePath}/`.replace(/\/+/g, '/');
			const path = `/${basePath}/${routePath}/`.replace(/\/+/g, '/');
			const component = (view.route?.component) ? view.route.component : null;
			const auth = (view.route?.auth !== undefined) ? view.route.auth : null;

			const name = (view.name) ? view.name : false;
			const menu = (view.menu) ? view.menu : {};
			const data = (view.data) ? view.data : {};

			this.views.push({
				parent,
				path,
				component,
				auth,
				name,
				menu,
				data,
			});

			if (view.childs) {
				this.view(view.childs, path);
			}
		});
	}

	generateRoutes = () => {
		let output = [];

		this.views.forEach((view) => {
			if (view.component) {
				output.push({
					path: view.path,
					component: view.component,
					auth: view.auth,
				});
			}
		});

		return output;
	}

	generateMenu = (type, parent = '/') => this.menu(type, parent)

	menu = (type, parent, depth = 1) => {
		let output = [];

		this.views.forEach((view) => {
			if (!parent || view.parent === parent) {
				const link = (view.component) ? view.path : null;
				const viewName = (view.name) ? view.name : '';
				const name = (view.menu?.name !== undefined) ? view.menu.name : viewName;

				if (view.menu?.type && view.menu.type.includes(type)) {
					output.push({
						depth,
						link,
						name,
						data: view.data,
						childs: (view.parent !== view.path) ? this.menu(type, view.path, depth + 1) : null,
					});
				}
			}
		});

		return (output.length) ? output : null;
	}
}


export default Modules;